import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { theme } from "../../constant/Theme.js";
import PersonformIcon from "../../Components/Icons/PersonformIcon.js";
import UpdatePersonMatterForm from "../../Components/Forms/PersonMatter/UpdatepersonMatter.js";
import { CustomStepper, PaperComponent } from "../../Components/Card/SettingCard.js";
const steps = [{ text: "Matter Details", label: "Matter & Role information", icon: <PersonformIcon /> }];
const EditPersonMatterPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const isStepOptional = (step) => {
    return step === 1;
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  const handleReset = () => {
    setActiveStep(0);
  };
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: theme?.palette?.primary?.main }}>
              Persons /
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary }}>
                {" "}
                Edit Matter
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  // backgroundColor: theme?.palette?.background?.paper,
                  color: "lightgray",
                }}
              >
                <Grid container>
                  <Grid item xs={12} style={{ background: theme?.palette?.primary?.sidebar, borderRadius: "8px" }}>
                    <CustomStepper steps={steps} activeStep={activeStep} isStepSkipped={isStepSkipped} theme={theme} />
                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <UpdatePersonMatterForm activeStep={activeStep} handleNext={handleNext} handleSkip={handleSkip} handleBack={handleBack} steps={steps} isStepOptional={isStepOptional} />
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditPersonMatterPage;

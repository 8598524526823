import React from "react";
import { Box, CssBaseline, Grid, Paper, Typography } from "@mui/material";
import { theme } from "../../constant/Theme";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useLocation, useNavigate } from "react-router-dom";
// for the tabs heading
const SettingCard = ({ title, children, formik }) => {
  return (
    <Box
      sx={{
        minHeight: "auto",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />

      <Grid container spacing={0}>
        {/* spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3} */}
        <Grid item xs={12} sm={12} style={{ background: "#F9FAFB", padding: "23px", marginBottom: "20px", borderRadius: "8px", height: "87px" }}>
          <Typography
            variant="h5"
            sx={{
              ...theme?.typography?.title,
              fontFamily: theme.typography?.fontFamily || "Arial",
              color: theme.palette?.primary?.main,
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      {children}
    </Box>
  );
};

export default SettingCard;
// for the single icon and heading
export const NewAddHeading = ({ title, subTitle, icon }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      sx={{ display: "flex", alignItems: "center", pl: 2 }}
      style={{
        background: theme?.palette?.primary?.sidebar,
        borderBottom: `1px solid ${theme?.palette?.primary?.backgroundSidebar}`,
        height: "111px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Box
        component="div"
        sx={{
          borderRadius: "8px",
          display: "flex",
          width: "50px",
          justifyContent: "center",
          alignItems: "center",
          height: "50px",
          // backgroundImage: theme?.palette?.primary?.iconColor,
          backgroundColor: "#FFFFFF",
          border: `1px solid ${theme?.palette?.secondary?.btnPrimary}`,
        }}
      >
        {icon}
      </Box>
      <Box component="section" sx={{ p: 2, display: "flex", flexDirection: "column" }}>
        <Typography
          variant="h5"
          color={theme?.palette?.secondary?.btnPrimary}
          sx={{
            ...theme?.typography?.subHeading,
            fontFamily: theme?.typography?.fontFamily,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            // color: "#8A8BA1",
            fontFamily: theme?.typography?.fontFamily,
            color: theme?.palette?.primary?.placeholderColor,
            fontSize: "14px",
            fontWeight: "400",
            ...theme?.typography?.smallHeading,
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </Grid>
  );
};
// for the main paper component
export const PaperComponent = ({ children }) => {
  return (
    <Paper
      sx={{
        // pt: 2,
        // pl: 2,
        // pr: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme?.palette?.background?.paper,
        boxShadow: 0,
        border: `1px solid ${theme?.palette?.primary?.backgroundSidebar}`,
        borderRadius: "8px",
      }}
    >
      {children}
    </Paper>
  );
};

// for the stepper icon
export const CustomStepper = ({ steps, activeStep, isStepSkipped, theme }) => {
  const location = useLocation();
  return (
    <Stepper
      sx={{ height: location.pathname !== "/form" ? "111px" : "40px", paddingLeft: "10px" }}
      activeStep={activeStep}
      connector={
        <ArrowForwardIosIcon
          sx={{
            display: { xs: "none", sm: "block" },
            marginLeft: "15px",
            marginRight: "15px",
            width: { sm: "15px", md: "24px" },
            height: { sm: "15px", md: "24px" },
            color: theme?.palette?.primary?.main,
          }}
        />
      }
    >
      {steps.map((step, index) => {
        const stepProps = {};
        const labelProps = {
          optional: (
            <Typography
              variant="caption"
              sx={{
                color: theme?.palette?.primary?.subPrimary,
                fontFamily: theme?.typography?.fontFamily,
                fontSize: "14px",
                ...theme?.typography?.smallHeading,
              }}
            >
              {step.label}
            </Typography>
          ),
        };

        if (isStepSkipped(index)) {
          stepProps.completed = false;
        }

        return (
          <Step
            key={index}
            {...stepProps}
            sx={{
              display: activeStep === index ? { xs: "block", sm: "block" } : { xs: "none", sm: "block" },
              "& .MuiStepLabel-root .Mui-completed": {
                color: activeStep === index ? theme?.palette?.secondary?.btnPrimary : theme?.palette?.primary?.main,
                ...theme?.typography?.subheading,
              },
              "& .MuiStepLabel-root .Mui-disabled": {
                color: activeStep === index ? theme?.palette?.primary?.main : theme?.palette?.primary?.subPrimary,
                ...theme?.typography?.subheading,
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: activeStep === index ? theme?.palette?.secondary?.btnPrimary : theme?.palette?.secondary?.btnPrimary,
                ...theme?.typography?.subheading,
              },
              "& .Mui-completed": { color: "white" },
              " & .MuiStepLabel-label ": {
                color: "white",
              },
            }}
          >
            <StepLabel
              {...labelProps}
              icon={
                <Box
                  component="div"
                  sx={{
                    borderRadius: "8px",
                    display: "flex",
                    width: "50px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                    backgroundColor: activeStep === index ? "white" : "white",
                    border: `1px solid ${activeStep === index ? `${theme?.palette?.secondary?.btnPrimary}` : `${theme?.palette?.primary?.subPrimary}`}`,
                    color: activeStep === index ? "#FFFFFF" : `${theme?.palette?.primary?.main}`,
                  }}
                >
                  {/* {step.icon} */}
                  {React.cloneElement(step.icon, {
                    color: activeStep === index ? `${theme?.palette?.secondary?.btnPrimary}` : `${theme?.palette?.primary?.subPrimary}`,
                  })}
                </Box>
              }
            >
              {step.text}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

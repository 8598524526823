import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import BalanceIcon from "../../Components/Icons/BalanceIcon";
import UpdateIssueForm from "../../Components/Forms/IssueCaseForm/UpdateIssuseForm";
import FeedIcons from "../../Components/Icons/FeedIcon";
import useScrollToRef from "../../hooks/useScrollToRef";
import { theme } from "../../constant/Theme";
import { CustomStepper, PaperComponent } from "../../Components/Card/SettingCard";
const steps = [
  { text: "Basic Details", label: "Jurisprudence information", icon: <FeedIcons color="#2B2C40" /> },
  { text: "Case 1", label: "Case 1 details", icon: <BalanceIcon color="#2B2C40" /> },
  { text: "Case 2", label: "Case 2 details", icon: <BalanceIcon color="#2B2C40" /> },
  { text: "Case 3", label: "Case 3 details", icon: <BalanceIcon color="#2B2C40" /> },
];

const EditIssuesPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const formRef = useRef(null);
  const navbarRef = useRef(null);
  const scrollToRef = useScrollToRef(navbarRef);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll to top
    }
  }, [activeStep]);
  const handleBack = () => {
    scrollToRef();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }
    scrollToRef();

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
    scrollToRef();
  };

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography ref={navbarRef} variant="h5" sx={{ color: theme?.palette?.primary?.main, ...theme?.typography?.title }}>
              Jurisprudence /
              <Box component="span" sx={{ color: theme?.palette?.primary?.subPrimary, ...theme?.typography?.title }}>
                {" "}
                Edit Jurisprudence
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme?.palette?.background?.paper,
                  color: "lightgray",
                }}
              >
                <Grid container>
                  <Grid item xs={12} style={{ background: theme?.palette?.primary?.sidebar, borderRadius: "8px" }}>
                    <CustomStepper steps={steps} activeStep={activeStep} isStepSkipped={isStepSkipped} theme={theme} />

                    {activeStep === steps.length ? (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                          <Box sx={{ flex: "1 1 auto" }} />
                          <Button onClick={handleReset}>Reset</Button>
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <UpdateIssueForm
                          activeStep={activeStep}
                          scrollToRef={scrollToRef}
                          handleNext={handleNext}
                          handleSkip={handleSkip}
                          handleBack={handleBack}
                          steps={steps}
                          isStepOptional={isStepOptional}
                          ref={formRef}
                        />
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditIssuesPage;

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { state } from "../../../constant/ConstantStateData.js";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, Typography, TextField, Button, Autocomplete, InputAdornment, FormControl, Select, MenuItem, InputLabel, FormHelperText, Paper } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service.js";
import notificationService from "../../../services/notification.service.js";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade.js";

const UpdateGeneratePleadingForm = () => {
  const { user } = useSelector((state) => state?.reducer);
  const location = useLocation();
  const { generateDocument } = useMultiFileUpload();

  const { userData } = location.state || {};

  const pleadingGenerate = userData;

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = user?.userData?.token;

  const [allCase, setAllCase] = useState([]);
  const [allDocument, setAllDocument] = useState([]);

  const formik = useFormik({
    initialValues: {
      pleadingId: pleadingGenerate.id,
      caseId: pleadingGenerate.caseId,
      title: pleadingGenerate?.title,
      case: pleadingGenerate?.case,
      type: userData?.deposition,
      caseId: pleadingGenerate?.caseId,
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Rquired"),
      case: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const selectedDoc = allDocument.find((doc) => doc._id === values.type);
      const token = user?.userData?.token;

      const UpdatepleadingDataSaving = {
        pleadingId: values.pleadingId,
        caseId: values.caseId,
        depositionId: values.type,
        title: values.title,
        status:(values.caseId !== userData.caseId || values.type !== userData?.deposition) ?'pending':pleadingGenerate.status,
        witnessId: selectedDoc?.witness[0]?._id,
      };

      const response = await axios.put(process.env.REACT_APP_BACKEND_URL + "pleadingGenerate", UpdatepleadingDataSaving, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response?.status == 200) {
        const formData = new FormData();

        const data = {
          firstName: user.userData.firstName,
          lastName: user.userData.lastName,
          email: user.userData.email,
          caseName: values.case,
          witnessName: selectedDoc.witness[0].firstName + " " + selectedDoc.witness[0].lastName,
          identifyIssue: "", // Assuming this will be filled in later
          uploadDepostion: selectedDoc.fileUrl,
          organizationId: user?.organizationData?._id,
          userId: user.userData.userId,
          path: `organization/${user?.organizationData?._id}/${user.userData.userId}/statementOfMaterialFact/${values.title}`,
          ModuleName: "statementOfMaterialFact",
        };

        const data1 = {
          file: selectedDoc.fileUrl,
          name: values.title,
          issue: "",
          organization_id: user?.organizationData?._id,
          user_id: user?.userData.userId,
          api_key: process.env.CHATGTP_OPEN_API_KEY || "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });

        if (values.caseId !== userData.caseId || values.type !== userData?.deposition) {
          generateDocument({
            id: values.pleadingId,
            title: values?.title,
            formData: data1,
          });
        }
        // notificationService.success("Pleading record has been updated successfully.");
        resetForm();
        setIsSubmitting(true);

        navigate("/Pleading");
      }
      // mutation.mutate(values);
    },
  });

  const getAllCase = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "caseDropdown", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllDepositon = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + `pleadingGenerate/depositionDropdown/${formik?.values.caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setAllDocument(response.data);
      }
    } catch (error) {}
  };

  const queryClient = useQueryClient();
  const { setBearerToken, put } = useAxios();
  const setClientData = async (newData) => {
    setBearerToken(token);
    const response = await put(`/pleadingGenerate`, newData);
    return response.data;
  };

  const mutation = useMutation(setClientData, {
    onSuccess: () => {
      showSuccessToastMessage("Record is successfull updated!");
      navigate("/pleading");
      queryClient.invalidateQueries("pleadingGenerate");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });

  useEffect(() => {
    getAllCase();
    getAllDepositon();
  }, [formik?.values?.caseId]);
  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.case && Boolean(formik.errors.case)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <Autocomplete
                id="case"
                options={allCase}
                disableClearable
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={allCase.find((option) => option.title === formik.values.case) || null}
                onChange={(event, newValue) => {
                  formik.setFieldValue("case", newValue?.title || "");
                  formik.setFieldValue("caseId", newValue?._id || "");
                  formik.setFieldValue("type", "");
                  formik.setFieldValue("data", "");
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Matter"
                    variant="outlined"
                    required
                    fullWidth
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    InputLabelProps={{
                      sx: {
                        color: "#C8C9DB",
                        "&.Mui-focused": {
                          color: "#A6A7C1",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: "#A6A7C1",
                        "& fieldset": {
                          borderColor: "#4A4C64",
                        },
                        "&:hover fieldset": {
                          borderColor: "#C8C9DB",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#A6A7C1",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#323249", color: "#C8C9DB" }} />}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799",
                    },
                  },
                }}
                noOptionsText="No matter found"
              />
            </FormControl>
          </Grid>

          {/* deposiiton */}

          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              required
              error={formik.touched?.type && Boolean(formik.errors?.type)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <InputLabel
                id="type-label"
                sx={{
                  color: "#C8C9DB",
                  // Change label color
                  "&.Mui-focused": {
                    color: "#A6A7C1", // Change label color when focused
                  },
                }}
              >
                Deposition
              </InputLabel>

              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                name="type"
                label="Deposition"
                value={formik.values.type || ""}
                onBlur={formik.handleBlur}
                onChange={(event) => {
                  formik.setFieldValue("type", event.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 200, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select deposition</em>
                </MenuItem>

                {allDocument.map((doc) => (
                  <MenuItem key={doc._id} value={doc._id}>
                    {doc.depositionFile}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.type && formik.errors.type && <FormHelperText>{formik.errors.type}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              error={formik.touched.title && formik.errors?.title}
              autoComplete="given-title"
              required
              name="title"
              id="title"
              label="Title"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              helperText={formik.touched?.title && formik.errors?.title}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "#D32F2F" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.title && Boolean(formik.errors.title) ? "#D32F2F" : "#C8C9DB",
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: formik.touched?.title && Boolean(formik.errors?.title) ? "#D32F2F" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: "#4A4C64",
                  color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Generate
            </Button>

            <Button
              onClick={() => navigate("/pleading")}
              variant="contained"
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateGeneratePleadingForm;

import React, { Suspense, lazy, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Box, LinearProgress, Skeleton, Stack } from "@mui/material";
import ProtectedRoute from "../pages/ProtectedRoute";
import EditIssuesPage from "../pages/Issues/EditIssuePage.js";
import ViewDetailIssue from "../pages/Issues/ViewDetailIssuse.js";
import RolePage from "../pages/Role/index.js";
import AddNewRolePage from "../pages/Role/AddNewRole.js";
import AddNewPermissionPage from "../pages/Role/AddNewPermission.js";
import EditPermissionPage from "../pages/Role/EditPermission.js";
import EditRolePage from "../pages/Role/EditRole.js";
import ForgetPassword from "../pages/ForgetPassword/index.js";
import ResetPassword from "../pages/ResetPassword/index.js";
import DocumentPleading from "../pages/DocumentPleading/index.js";
import AddNewDocumentPleading from "../pages/DocumentPleading/AddNewDocumentPleading.js";
import EditGeneratePleading from "../pages/DocumentPleading/EditNewDocumentPleading.js";
import QueriesPage from "../pages/Queries/index.js";
import ViewDocumentPleadingDetail from "../pages/DocumentPleading/ViewDocumentPleadingDetail.js";
import TwoStepVerification from "../pages/TwoStepVerification/index.js";
import DocumentMedicalPleading from "../pages/DocumentMedical/index.js";
import AddNewDocumentMedical from "../pages/DocumentMedical/AddNewDocumentMedical.js";
import PersonsMattersPages from "../pages/PersonsMatter/index.js";
import PersonMatterForm from "../Components/Forms/PersonMatter/index.js";
import AddNewPersonMatter from "../pages/PersonsMatter/AddNewPersonMatter.js";
import EditPersonMatterPage from "../pages/PersonsMatter/EditPersonMatter.js";
import ViewFilePage from "../pages/ViewFile/index.js";
import { useMultiFileUpload } from "../context/useMultiFileUploade.js";
import ViewPersonMatterDetails from "../pages/PersonsMatter/ViewPersonMatterDetails.js";
// Lazy load components
const HomePage = lazy(() => import("../pages/index.js"));
const SignIn = lazy(() => import("../pages/SignIn"));
const IssuesPage = lazy(() => import("../pages/Issues/index.js"));
const CasesPage = lazy(() => import("../pages/Cases/index.js"));
const PersonsPage = lazy(() => import("../pages/Persons/index.js"));
const MedicalRecord = lazy(() => import("../pages/Medical/index.js"));
const ExhibitPage = lazy(() => import("../pages/Exhibits/index.js"));
const DiscoveryPage = lazy(() => import("../pages/Discovery/index.js"));
const DepositionsPage = lazy(() => import("../pages/Depositions/index.js"));
const CorrespondencePage = lazy(() => import("../pages/Correspondence/index.js"));
const PleadingsPage = lazy(() => import("../pages/Pleadings/index.js"));
const SpecificDashboard = lazy(() => import("../pages/SpecificDashboard/index.js"));
const CheckoutForm = lazy(() => import("../pages/Checkout"));
const ViewUserDetail = lazy(() => import("../pages/Users/ViewUserDetail.js"));
const AddNewIssuesPage = lazy(() => import("../pages/Issues/AddNewIssuesPage.js"));
const Clients = lazy(() => import("../pages/Clients/index.js"));
const DashboardPage = lazy(() => import("../pages/Dashboard/index.js"));
const AddNewClientPage = lazy(() => import("../pages/Clients/AddNewClientPage.js"));
const EditClientPage = lazy(() => import("../pages/Clients/EditClientPage.js"));
const ViewClientDetail = lazy(() => import("../pages/Clients/ViewClientDetails.js"));
const AddNewCasePage = lazy(() => import("../pages/Cases/AddNewCasePage.js"));
const ViewCaseDetail = lazy(() => import("../pages/Cases/ViewCaseDetail.js"));
const EditCasePage = lazy(() => import("../pages/Cases/EditCasePage.js"));
// const MattersPages = lazy(() => import("../pages/Matters/index.js"));
// const AddNewMattersPage = lazy(() => import("../pages/Matters/AddNewMattersPage.js"));
const AddNewPersonPage = lazy(() => import("../pages/Persons/AddNewPerson.js"));
const ViewPersonDetails = lazy(() => import("../pages/Persons/ViewPersonDetails.js"));
// const EditMatterPage = lazy(() => import("../pages/Matters/EditMatterPage.js"));
// const ViewMatterDetail = lazy(() => import("../pages/Matters/ViewMatterDetail.js"));
const AddNewMedicalPage = lazy(() => import("../pages/Medical/AddNewMedicalPage.js"));
const FormVisit = lazy(() => import("../pages/FormVisit/index.js"));
const EditPersonPage = lazy(() => import("../pages/Persons/EditPersonPage.js"));
const EditMedicalPage = lazy(() => import("../pages/Medical/EditMedicalPage.js"));
const ViewMedicalDetail = lazy(() => import("../pages/Medical/ViewMedicalDetail.js"));
const AddNewExhibitPage = lazy(() => import("../pages/Exhibits/AddNewExhibitPage.js"));
const EditExhibitPage = lazy(() => import("../pages/Exhibits/EditExhibitPage.js"));
const AddNewDiscoveryPage = lazy(() => import("../pages/Discovery/AddNewDiscoverypage.js"));
const ResetPasswordUserPage = lazy(() => import("../pages/Users/ResetPasswordUserPage.js"));
const AddNewUserPage = lazy(() => import("../pages/Users/AddNewUserPage.js"));
const EditUserPage = lazy(() => import("../pages/Users/EditUserPage.js"));
const EditDiscoveryPage = lazy(() => import("../pages/Discovery/EditDiscoveryPage.js"));
const ViewExhibitDetail = lazy(() => import("../pages/Exhibits/ViewExhibitDetail.js"));
const AddNewDepositionsPage = lazy(() => import("../pages/Depositions/AddNewDepositionsPage.js"));
const ViewDiscoveryDetailPage = lazy(() => import("../pages/Discovery/ViewDiscoveryDetailPage.js"));
const EditDispositionPage = lazy(() => import("../pages/Depositions/EditDepositionsPage.js"));
const ViewDepositionsDetailsPage = lazy(() => import("../pages/Depositions/ViewDepositionsDetailsPage.js"));
const AddNewCorrespondencePage = lazy(() => import("../pages/Correspondence/AddNewCorrespondence.js"));
const CourtsPage = lazy(() => import("../pages/Courts/index.js"));
const AddNewCourtPage = lazy(() => import("../pages/Courts/AddNewCourtsPage.js"));
const ViewCourtDetail = lazy(() => import("../pages/Courts/ViewCourtDetails.js"));
const EditCourtPage = lazy(() => import("../pages/Courts/EditCourtPage.js"));
const ViewCorrespondenceDetail = lazy(() => import("../pages/Correspondence/ViewCorrespondence.js"));
const EditCorrespondencePage = lazy(() => import("../pages/Correspondence/EditCorrespondencePage.js"));
const AddNewPleadingsPage = lazy(() => import("../pages/Pleadings/AddNewPleadings.js"));
const ViewPleadingDetail = lazy(() => import("../pages/Pleadings/ViewPleadingDetails.js"));
const EditPleadingsPage = lazy(() => import("../pages/Pleadings/EditPleading.js"));

const Routers = () => {
  const { matter } = useMultiFileUpload();

  const removeInitialLoader = () => {
    const loader = document.getElementById("initial-loader");
    if (loader) {
      loader.style.display = "none";
    }
  };

  useEffect(() => {
    removeInitialLoader();
  }, []);
  return (
    <>
      <Suspense
        fallback={
          <Box sx={{ backgroundColor: "#F9FAFB", height: "100vh" }}>
            <Stack sx={{ width: "100%", color: "#8D43FE" }} spacing={2}>
              <LinearProgress color="inherit" />
            </Stack>
          </Box>
        }
      >
        {/* <Top /> */}
        <Routes>
          {/* wrapped the layout path */}
          <Route index path="/login" element={<SignIn />} />
          <Route index element={<ProtectedRoute>{<HomePage />}</ProtectedRoute>} />

          <Route index path="/Jurisprudence" element={<ProtectedRoute>{<IssuesPage />}</ProtectedRoute>} />
          <Route index path="/Jurisprudence/addJurisprudence" element={<ProtectedRoute>{<AddNewIssuesPage />}</ProtectedRoute>} />
          <Route index path="/Jurisprudence/updateJurisprudence" element={<ProtectedRoute>{<EditIssuesPage />}</ProtectedRoute>} />
          <Route index path="/Jurisprudence/viewJurisprudence" element={<ProtectedRoute>{<ViewDetailIssue />}</ProtectedRoute>} />

          <Route index path="/clients" element={<ProtectedRoute>{<Clients />}</ProtectedRoute>} />
          <Route index path="/clients/addClient" element={<ProtectedRoute>{<AddNewClientPage />}</ProtectedRoute>} />
          <Route index path="/clients/updateClient" element={<ProtectedRoute>{<EditClientPage />}</ProtectedRoute>} />
          <Route index path="/clients/viewClient" element={<ProtectedRoute>{<ViewClientDetail />}</ProtectedRoute>} />

          <Route index path="/courts" element={<ProtectedRoute>{<CourtsPage />}</ProtectedRoute>} />
          <Route index path="/courts/addCourts" element={<ProtectedRoute>{<AddNewCourtPage />}</ProtectedRoute>} />
          <Route index path="/courts/updateCourts" element={<ProtectedRoute>{<EditCourtPage />}</ProtectedRoute>} />
          <Route index path="/courts/viewCourts" element={<ProtectedRoute>{<ViewCourtDetail />}</ProtectedRoute>} />

          <Route index path="/matters" element={<ProtectedRoute>{<CasesPage />}</ProtectedRoute>} />
          <Route index path="/matters/addMatter" element={<ProtectedRoute>{<AddNewCasePage />}</ProtectedRoute>} />
          <Route index path="/matters/viewMatter" element={<ProtectedRoute>{<ViewCaseDetail />}</ProtectedRoute>} />
          <Route index path="/matters/updateMatter" element={<ProtectedRoute>{<EditCasePage />}</ProtectedRoute>} />
          {/* <Route index path="/matters" element={<ProtectedRoute>{<MattersPages />}</ProtectedRoute>} />
          <Route index path="/matters/addMatters" element={<ProtectedRoute>{<AddNewMattersPage />}</ProtectedRoute>} />
          <Route index path="/matters/updateMatter" element={<ProtectedRoute>{<EditMatterPage />}</ProtectedRoute>} />
          <Route index path="/matters/viewMatter" element={<ProtectedRoute>{<ViewMatterDetail />}</ProtectedRoute>} /> */}
          <Route index path="/persons" element={<ProtectedRoute>{<PersonsPage />}</ProtectedRoute>} />
          <Route index path="/persons/addPersons" element={<ProtectedRoute>{<AddNewPersonPage />}</ProtectedRoute>} />
          <Route index path="/persons/viewPersons" element={<ProtectedRoute>{<ViewPersonDetails />}</ProtectedRoute>} />
          <Route index path="/persons/updatePerson" element={<ProtectedRoute>{<EditPersonPage />}</ProtectedRoute>} />

          <Route index path="/persons/personsMatter/:personId" element={<ProtectedRoute>{<PersonsMattersPages />}</ProtectedRoute>} />
          <Route index path="/persons/personsMatter/addpersonMatter/:personId" element={<ProtectedRoute>{<AddNewPersonMatter />}</ProtectedRoute>} />
          <Route index path="/persons/personsMatter/ViewPersonMatter" element={<ProtectedRoute>{<ViewPersonMatterDetails />}</ProtectedRoute>} />
          <Route index path="/persons/personsMatter/updatepersonMatter" element={<ProtectedRoute>{<EditPersonMatterPage />}</ProtectedRoute>} />

          <Route index path="/medical" element={<ProtectedRoute>{<MedicalRecord />}</ProtectedRoute>} />
          <Route index path="/medical/addMedical" element={<ProtectedRoute>{<AddNewMedicalPage />}</ProtectedRoute>} />
          <Route index path="/medical/updateMedical" element={<ProtectedRoute>{<EditMedicalPage />}</ProtectedRoute>} />
          <Route index path="/medical/viewMedical" element={<ProtectedRoute>{<ViewMedicalDetail />}</ProtectedRoute>} />

          <Route index path="/exhibits" element={<ProtectedRoute>{<ExhibitPage />}</ProtectedRoute>} />
          <Route index path="/exhibits/addExhibit" element={<ProtectedRoute>{<AddNewExhibitPage />}</ProtectedRoute>} />
          <Route index path="/exhibits/updateExhibit" element={<ProtectedRoute>{<EditExhibitPage />}</ProtectedRoute>} />
          <Route index path="/exhibits/viewExhibit" element={<ProtectedRoute>{<ViewExhibitDetail />}</ProtectedRoute>} />

          <Route index path="/discovery" element={<ProtectedRoute>{<DiscoveryPage />}</ProtectedRoute>} />
          <Route index path="/discovery/addDiscovery" element={<ProtectedRoute>{<AddNewDiscoveryPage />}</ProtectedRoute>} />
          <Route index path="/discovery/updateDiscovery" element={<ProtectedRoute>{<EditDiscoveryPage />}</ProtectedRoute>} />
          <Route index path="/discovery/viewDiscovery" element={<ProtectedRoute>{<ViewDiscoveryDetailPage />}</ProtectedRoute>} />

          <Route index path="/depositions" element={<ProtectedRoute>{<DepositionsPage />}</ProtectedRoute>} />
          <Route index path="/depositions/addDepositions" element={<ProtectedRoute>{<AddNewDepositionsPage />}</ProtectedRoute>} />
          <Route index path="/depositions/updateDepositions" element={<ProtectedRoute>{<EditDispositionPage />}</ProtectedRoute>} />
          <Route index path="/depositions/viewDepositions" element={<ProtectedRoute>{<ViewDepositionsDetailsPage />}</ProtectedRoute>} />

          <Route index path="/correspondence" element={<ProtectedRoute>{<CorrespondencePage />}</ProtectedRoute>} />
          <Route index path="/correspondence/addCorrespondence" element={<ProtectedRoute>{<AddNewCorrespondencePage />}</ProtectedRoute>} />
          <Route index path="/correspondence/updateCorrespondence" element={<ProtectedRoute>{<EditCorrespondencePage />}</ProtectedRoute>} />
          <Route index path="/correspondence/viewCorrespondence" element={<ProtectedRoute>{<ViewCorrespondenceDetail />}</ProtectedRoute>} />

          <Route index path="/pleadings" element={<ProtectedRoute>{<PleadingsPage />}</ProtectedRoute>} />
          <Route index path="/pleadings/addPleadings" element={<ProtectedRoute>{<AddNewPleadingsPage />}</ProtectedRoute>} />
          <Route index path="/pleadings/viewPleadings" element={<ProtectedRoute>{<ViewPleadingDetail />}</ProtectedRoute>} />
          <Route index path="/pleadings/updatePleadings" element={<ProtectedRoute>{<EditPleadingsPage />}</ProtectedRoute>} />

          <Route index path="/user/addUser" element={<ProtectedRoute>{<AddNewUserPage />}</ProtectedRoute>} />
          <Route index path="/user/resetPassword" element={<ProtectedRoute>{<ResetPasswordUserPage />}</ProtectedRoute>} />
          <Route index path="/user/updateUser" element={<ProtectedRoute>{<EditUserPage />}</ProtectedRoute>} />
          <Route index path="/user/view" element={<ProtectedRoute>{<ViewUserDetail />}</ProtectedRoute>} />
          <Route index path="checkout" element={<ProtectedRoute>{<CheckoutForm />}</ProtectedRoute>} />
          {/* Account Form Visit */}
          <Route index path="/form" element={<ProtectedRoute>{<FormVisit />}</ProtectedRoute>} />
          {/* here is role defeined */}

          <Route index path="/role" element={<ProtectedRoute>{<RolePage />}</ProtectedRoute>} />
          <Route index path="/role/addRole" element={<ProtectedRoute>{<AddNewRolePage />}</ProtectedRoute>} />
          <Route index path="/role/addPermission" element={<ProtectedRoute>{<AddNewPermissionPage />}</ProtectedRoute>} />
          <Route index path="/role/UpdatePermission" element={<ProtectedRoute>{<EditPermissionPage />}</ProtectedRoute>} />
          <Route index path="/role/UpdateRole" element={<ProtectedRoute>{<EditRolePage />}</ProtectedRoute>} />

          {/* document pleading */}
          <Route index path="/pleading" element={<ProtectedRoute>{<DocumentPleading />}</ProtectedRoute>} />
          <Route index path="/pleading/generatePleading" element={<ProtectedRoute>{<AddNewDocumentPleading />}</ProtectedRoute>} />
          <Route index path="/pleading/UpdategeneratePleading" element={<ProtectedRoute>{<EditGeneratePleading />}</ProtectedRoute>} />
          <Route index path="/pleading/viewPleading" element={<ProtectedRoute>{<ViewDocumentPleadingDetail />}</ProtectedRoute>} />

          {/* document Medical */}
          <Route index path="/medicals" element={<ProtectedRoute>{<DocumentMedicalPleading />}</ProtectedRoute>} />
          <Route index path="/medicals/generateMedical" element={<ProtectedRoute>{<AddNewDocumentMedical />}</ProtectedRoute>} />
          <Route index path="/AIChatbot" element={<ProtectedRoute>{<QueriesPage />}</ProtectedRoute>} />

          <Route index path="/forget-password" element={<ForgetPassword />} />
          <Route index path="/reset-password" element={<ResetPassword />} />
          <Route index path="/two-step" element={<TwoStepVerification />} />
          <Route index path="/dashboard" element={<ProtectedRoute>{matter ? <SpecificDashboard /> : <DashboardPage />}</ProtectedRoute>} />

          {/* for the page view */}
          <Route index path="/view" element={<ProtectedRoute>{<ViewFilePage />}</ProtectedRoute>} />
          <Route index path="/specific-dashboard" element={<ProtectedRoute>{<SpecificDashboard />}</ProtectedRoute>} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Routers;

import React, { createContext, useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import slices from "./store/user/userSlice.js";
import { MultiFileUploadProvider } from "./context/useMultiFileUploade.js";
import { ToastContainer } from "react-toastify";
import "./App.css";
import Routers from "./Routers/Routers";
import { useNavigate } from "react-router-dom";
import PageRefreshWrapper from "./Components/PageRefreshWrapper/PageRefreshWrapper.js";
const AuthContext = createContext();

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.reducer?.user);
  // const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const isAuthenticated = userData !== null && typeof userData == "object" && Object.keys(userData).length > 0;
  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setUser(userData);
      dispatch(slices.clearUser()); // Clear existing user data
      dispatch(slices.setUser(userData)); // Set new user data
    }
  }, [userData]);

  const login = (userData) => {
    dispatch(slices.clearUser()); // Clear existing user data
    dispatch(slices.setUser(userData)); // Set new user data
    setUser(userData);
  };

  const logout = () => {
    dispatch(slices.setUser(userData)); // Clear user data from Redux
    setUser(null);
    navigate("/login");
  };

  return (
    
    <AuthContext.Provider value={{ user, isAuthenticated, login, logout }}>
      <MultiFileUploadProvider token={userData?.token}>
        <ToastContainer autoClose={3000} />
        <PageRefreshWrapper>
          <Routers />
        </PageRefreshWrapper>
      </MultiFileUploadProvider>
    </AuthContext.Provider>
  );
}

export default App;
export const useAuth = () => useContext(AuthContext);

import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteModel from "../Modal/DeleteModel";
import { useNavigate } from "react-router-dom";
import useFileActions from "../../hooks/useFileActions";
import { Delete, LockReset, RemoveRedEye } from "@mui/icons-material";
import { BiSolidEditAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import { theme } from "../../constant/Theme";
import BalanceIcon from "@mui/icons-material/Balance"; // import CasesIcon from "../Components/Icons/CasesIcon";

const TableDropdown = ({ data, viewPageUrl, resetPassword, resetPasswordUrl, deleteUrl, isDelete, isDocument, editPageUrl, isDownload, onClickDownload, viewMatterUrl }) => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { handleFileAction } = useFileActions();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpen = () => {
    setOpen(true);
    handleCloseUserMenu();
  };
  const [open, setOpen] = React.useState(false);
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu}>
          <MoreVertIcon sx={{ color: theme?.palette?.primary?.placeholderColor }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: "30px",
          color: theme?.palette?.primary?.main, // Set text color to white
          "& .MuiPaper-root": {
            // Ensuring the dropdown has the correct background color
            backgroundColor: theme?.palette?.primary?.subSecondary,
            boxShadow: 0,
            border: "1px solid #387CFF ",
          },

          direction: "flex",
          flexDirection: "column",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem sx={{ color: theme?.palette?.primary?.main }} onClick={() => navigate(editPageUrl, { state: { userData: data } })}>
          <BiSolidEditAlt sx={{ color: theme?.palette?.primary?.main, fontSize: "22px" }} size={20} />
          <Typography
            color={theme?.palette?.primary?.main}
            sx={{
              width: "100%",
              paddingY: "2px",
              paddingX: "5px",
            }}
          >
            Edit Record
          </Typography>
        </MenuItem>
        {viewMatterUrl && (
          <MenuItem onClick={() => navigate(`${viewMatterUrl}/${data._id}`)}>
            <BalanceIcon sx={{ color: theme?.palette?.primary?.main, fontSize: "18px" }} size={20} />
            <Typography
              onClick={() => navigate(`${viewMatterUrl}/${data._id}`)}
              color={theme?.palette?.primary?.main}
              sx={{
                width: "100%",
                paddingY: "2px",
                paddingX: "5px",
              }}
            >
              View Matters
            </Typography>
          </MenuItem>
        )}
        
        <MenuItem onClick={() => navigate(viewPageUrl, { state: { viewRecord: data } })}>
          <RemoveRedEye sx={{ color: theme?.palette?.primary?.main, fontSize: "18px" }} size={20} />
          <Typography
            onClick={() => navigate(viewPageUrl, { state: { viewRecord: data } })}
            color={theme?.palette?.primary?.main}
            sx={{
              width: "100%",
              paddingY: "2px",
              paddingX: "5px",
            }}
          >
            View Record
          </Typography>
        </MenuItem>

        {resetPassword && (
          <>
            <MenuItem>
              <LockReset sx={{ color: theme?.palette?.primary?.main, fontSize: "22px" }} size={20} />
              <Typography
                onClick={() => navigate(resetPasswordUrl, { state: { viewRecord: data } })}
                color={theme?.palette?.primary?.main}
                sx={{
                  width: "100%",
                  paddingY: "2px",
                  paddingX: "5px",
                }}
              >
                Reset Password
              </Typography>
            </MenuItem>
          </>
        )}

        {isDelete && (
          <>
            <MenuItem onClick={handleOpen}>
              <DeleteIcon sx={{ color: "#DC3545", fontSize: "22px" }} size={20} />
              <Typography
                color={"#DC3545"}
                sx={{
                  width: "100%",
                  paddingY: "2px",
                  paddingX: "5px",
                }}
              >
                Delete Record
              </Typography>
            </MenuItem>
          </>
        )}
        {isDownload && (
          <MenuItem>
            <GetAppIcon sx={{ color: theme?.palette?.primary?.main, fontSize: "22px" }} size={20} />
            <Typography
              onClick={() => onClickDownload(data)}
              color={theme?.palette?.primary?.main}
              sx={{
                width: "100%",
                paddingY: "2px",
                paddingX: "5px",
              }}
            >
              Download
            </Typography>
          </MenuItem>
        )}

        <DeleteModel data={data} deleteUrl={deleteUrl} setOpen={setOpen} open={open} />
      </Menu>
    </Box>
  );
};

export default TableDropdown;

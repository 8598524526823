import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import { theme } from "../../constant/Theme";

const Pagination = ({ setCurrentPage, currentPage, limit, setLimit, totalPage }) => {
  const handleChange = (event) => {
    setLimit(event.target.value);
  };
  const location = useLocation();

  return (
    <Grid container spacing={2} style={{ paddingBottom: location.pathname === "/persons/viewPersons" ? "16px" : "0" , paddingRight:'15px'}}>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          mt: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            // marginTop: "5px",
            color: theme?.palette?.primary?.placeholderColor,
            // fontFamily: theme?.typography?.fontFamily,
            ...theme?.typography?.bodyText,
            fontWeight: "400",
            fontSize: "16px",
            lineHeight:'22.4px'
          }}
        >
          Page {currentPage} of {totalPage == 0 ? 1 : totalPage}
          {/* of {limit} entries */}
        </Typography>
        <Box sx={{ minWidth: 80 }}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                color: theme?.palette?.primary?.placeholderColor, // Set text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme?.palette?.secondary?.inputColor, // Border color on hover
                },
              },
              "& .MuiSvgIcon-root": {
                color: theme?.palette?.primary?.placeholderColor,
              },
            }}
          >
            <Select value={limit} onChange={(event) => setLimit(event.target.value)} displayEmpty>
              {[10, 50, 100, 200, 500, 1000].map((option) => (
                <MenuItem key={option} value={option} selected={limit == option} sx={{ paddingTop: "0", paddingBottom: "0" }}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography
          variant="h5"
          sx={{
            // marginTop: "5px",
            color: theme?.palette?.primary?.placeholderColor,
            // fontFamily: theme?.typography?.fontFamily,
            ...theme?.typography?.bodyText,
            fontWeight: "400",
            fontSize: "16px",
            lineHeight:'22.4px'
          }}
        >
          entries per page
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} spacing={2} sx={{ display: "flex", justifyContent: "flex-end", gap: 2, paddingRight: "16px", marginTop: "10px"  }}>
        {currentPage > 1 && (
          <Button
            onClick={() => setCurrentPage((e) => (e == 1 ? 1 : e - 1))}
            variant="contained"
            sx={{
              width: "150px",
              height:'45px',
              // boxShadow: "0px 4px 8px #4A4C64",
              color: theme?.palette?.primary?.subPrimary,
              boxShadow:0,
              "&:hover, &:focus": {
                // boxShadow: "0px 6px 12px #4A4C64",
                backgroundColor: theme?.palette?.primary?.backgroundSidebar,
                color: theme?.palette?.primary?.subPrimary,
                boxShadow:0,
              },
              fontSize: "12px",
              backgroundColor: theme?.palette?.primary?.backgroundSidebar,
              fontWeight: "600",
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Previous
          </Button>
        )}

        {currentPage < totalPage && (
          <Button
            onClick={() => setCurrentPage((e) => e + 1)}
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "150px" },
              mt: { xs: 1, sm: 1.5 },
              mb: { xs: 0, sm: 2 },
              height: "45px",
              "&:hover, &:focus": {
                backgroundColor: "white",
                color: "#FFFFFF",
                backgroundColor: theme?.palette?.secondary?.btnPrimary,
                boxShadow: 0,
              },
              "&:disabled": {
                backgroundColor: theme?.palette?.secondary?.btnPrimary,
                color: "white",
                boxShadow: 0,
              },
              boxShadow: 0,
              fontSize: "12px",
              backgroundColor: theme?.palette?.secondary?.btnPrimary,
              fontWeight: "600",
              fontFamily: theme?.typography?.fontFamily,
            }}
          >
            Next
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default Pagination;

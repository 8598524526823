import React, { useEffect, useState } from "react";
import { inputPersonStyles } from "../../../pages/Persons/style";
import LabeledTextField, { CustomSelectDropDown, StateSelect } from "../../../constant/ConstantInputField";
import { Box, Button, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import * as Yup from "yup";
import { theme } from "../../../constant/Theme";
import PopupModel from "../../../Components/Modal";
import AddCompanyModel from "../../Modal/PersonsModel/AddCompanyModel";
import { state } from "../../../constant/ConstantStateData";
import axios from "axios";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useAxios } from "../../../services/http.service";

const PersonTabForm = ({ formik }) => {
  const [emailError, setEmailError] = useState();
  const [customCompanyName, setCustomCompanyName] = useState("");
  const [allClient, setAllClient] = useState([]);
  const [openCase, setOpenCase] = useState(false);
  const handleOpen = () => setOpenCase(true);
  const handleClose = () => setOpenCase(false);
  let stateNames = state;
  const { user } = useSelector((state) => state?.reducer);

  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "getClients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        let result = response?.data?.data.map((v) => v.companyName).filter((name) => name && name.trim() !== ""); // Filter out empty names

        if (result.length === 0) {
          result = ["N/A"]; // Set to "N/A" if no valid data is returned
        }

        const currentCompanyName = formik.values.companyName;
        if (currentCompanyName && currentCompanyName.trim() !== "") {
          setAllClient([...result, currentCompanyName]);
        } else {
          setAllClient(result);
        }
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClient(["N/A"]); // Set to "N/A" if the API call fails
    }
  };

  useEffect(() => {
    getAllClients();
  }, []);

  const { get, setBearerToken } = useAxios();

  // for type api
  const fetchData = async () => {
    const token = user?.userData?.token;
    setBearerToken(token);
    const response = await get("/companyType");
    return [...allClient, ...(Array?.isArray(response?.data) ? response?.data : [])];
  };
  const { data, isLoading } = useQuery(["companyType", customCompanyName], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <Box>
      <PopupModel
        open={openCase}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "500px" },
          bgcolor: "background.paper",
          boxShadow: 0,
          p: 4,
          height: { xs: "auto", md: "auto" },
          backgroundColor: "#FFFFFF",
          boxShadow: 0,
          border: "1px solid #FFFFFF",
        }}
        handleClose={handleClose}
        cards={
          <AddCompanyModel
            setAllClient={setAllClient}
            formik={formik}
            customCompanyName={customCompanyName}
            setCustomCompanyName={setCustomCompanyName}
            closeModal={handleClose}
            url="companyType"
            cahcheKey={"companyType"}
            data={data}
          />
        }
      />
      <Grid container spacing={3}>
        <Grid item xs={6} sm={2}>
          <Typography variant="body1" sx={{ color: theme?.palette?.primary?.main, mb: "4px" }}>
            Prefix
          </Typography>
          <CustomSelectDropDown
            required={false}
            label="Gender"
            name="gender"
            value={formik.values.gender}
            error={formik.touched.gender && Boolean(formik.errors.gender)}
            helperText={formik.touched.gender ? formik.errors.gender : ""}
            options={["Mr", "Mrs", "Ms"]}
            formik={formik}
            theme={theme}
            inputStyles={inputPersonStyles?.role(formik)}
            placeholder={"Select Gender"}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <LabeledTextField
            formik={formik}
            name="firstName"
            label="First Name"
            placeholder="Enter First name"
            required
            inputStyles={inputPersonStyles?.firstName(formik)} // Custom styles
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabeledTextField
            formik={formik}
            name="lastName"
            label="Last Name"
            placeholder="Enter Last name"
            required={true}
            inputStyles={inputPersonStyles?.lastName(formik)} // Custom styles
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <LabeledTextField formik={formik} name="profession" label="Suffix" placeholder="Suffix" inputStyles={inputPersonStyles?.role(formik)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField formik={formik} name="email" label="Email" placeholder="Enter Email" error={emailError} helperText={emailError} inputStyles={inputPersonStyles?.email(formik)} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="body1"
            sx={{
              color: theme?.palette?.primary?.main,
              marginBottom: "3px",
              fontFamily: theme?.typography?.fontFamily,
              ...theme?.typography?.inputHeading,
            }}
          >
            Company Name
          </Typography>
          <Box sx={{ display: "flex" }}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              helperText={formik.touched?.companyName && formik.errors?.companyName}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: theme?.palette?.primary?.placeholderColor,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    borderRightColor: "#686DFC",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderTopLeftRadius: "4px",
                    borderBottomLeftRadius: "4px",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    borderColor: theme?.palette?.secondary?.main,
                    borderRightColor: "#686DFC",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.primary?.main, // Yellow border color on focus
                  },
                  ...theme?.typography?.inputPlaceHolder,
                },
                "& .MuiSvgIcon-root": {
                  color: theme?.palette?.primary?.placeholderColor,
                },
              }}
            >
              <Select
                labelId="company-label"
                id="demo-simple-select"
                autoComplete="off"
                placeholder="Select Company name"
                displayEmpty
                name="companyName"
                value={formik.values?.companyName}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.companyName && formik.errors.companyName}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      color: theme?.palette?.primary?.main,
                      maxHeight: 150, // Adjust as needed
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26", // Hover background color
                          color: "#686DFC", // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select company</Typography>
                </MenuItem>
                {[...new Set(data)]
                  ?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                  ?.map((row, index) => {
                    return (
                      <MenuItem key={index} value={row} disabled={row === "N/A"}>
                        {row?.length >= 25 ? row.substring(0, 25) + "..." : row}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <Button
              variant="outlined"
              type="button"
              sx={{
                padding: { xs: "10px 25px", xl: "20px 50px" },
                color: "#686DFC",
                fontSize: { xs: "10px", xl: "15px" },
                borderColor: "white",
                // border: "1px solid",
                borderTopLeftRadius: "0",
                borderBottomLeftRadius: "0",
                border: "1px solid",
                borderLeft: "none",
                "&:hover": {
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  border: "1px solid",
                  borderLeft: "none",
                  borderColor: "rgba(104, 109, 252, 0.7)",
                  color: "rgba(104, 109, 252, 0.7)",
                },
                height: "44px",
              }}
              onClick={handleOpen}
            >
              Add
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField
            formik={formik}
            name="phone"
            label="Phone No"
            placeholder="Enter Phone No"
            inputStyles={inputPersonStyles?.phone(formik)} // Custom styles
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LabeledTextField formik={formik} name="address" label="Street Address" placeholder="Enter Street Address" sx={inputPersonStyles?.address(formik)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabeledTextField formik={formik} name="city" label="City" placeholder="Enter City" sx={inputPersonStyles?.city(formik)} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            variant="body1"
            sx={{
              color: theme?.palette?.primary?.main,
              mb: "3px",
            }}
          >
            State
          </Typography>
          <StateSelect
            required={false}
            formik={formik} // Pass formik to handle form state and validation
            placeholder="Select State"
            options={stateNames} // State options array
            theme={theme} // Custom theme for styling
            inputPersonStyles={inputPersonStyles} // Custom input styles
            noOptionsText="No state found" // Custom message when no options are found
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabeledTextField formik={formik} name="zipcode" label="Zip Code" placeholder="Enter Zip Code" sx={inputPersonStyles?.zipCode(formik)} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PersonTabForm;

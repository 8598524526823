import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useFileActions from "../../hooks/useFileActions";
import GetAppIcon from "@mui/icons-material/GetApp";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Word from "../../Components/Icons/Word";
import Excel from "../../Components/Icons/Excel";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { theme } from "../../constant/Theme";
import { inputDocumentPleadingStyles } from "./style";
import { BackBtn } from "../../Components/Button";
const ViewDocumentPleadingDetail = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const { viewRecord } = location.state || {};
  const { handleFileAction, FileViewModal } = useFileActions();
  let CreatedDate = "";
  if (viewRecord?.createdAt) {
    const year = new Date(viewRecord?.createdAt).getFullYear();
    const month = new Date(viewRecord?.createdAt).toLocaleString("en-US", {
      month: "long",
    });
    const day = new Date(viewRecord?.createdAt).getDate();
    CreatedDate = `${day ?? ""} ${month ?? ""} ${year ?? ""}`;
  }
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperComponent>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: theme?.palette?.primary?.bodyColor,
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: theme?.palette?.primary?.main,
                      }}
                    >
                      <Typography
                        sx={{
                          // fontWeight: "600",
                          lineHeight: "33.6px",
                          fontSize: "28px !important",
                          fontFamily: "Public Sans ,sans-serif",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        {viewRecord?.title}
                      </Typography>

                      <BackBtn title={"back"} link={"/pleading"} />
                    </Box>
                  </Grid>
                </Grid>

                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box>
                      {/* <Typography
                      sx={{
                        color: "#A6A7C1 !important",
                        fontSize: "12px",
                        lineHeight: "24px",
                      }}
                    >
                      Date Created
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                      }}
                    >
                      {viewRecord.createdAt}
                    </Typography> */}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {/* Case Title Date of certification Note Type Document */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputDocumentPleadingStyles?.viewHeading}>Matter</Typography>
                      <Typography sx={inputDocumentPleadingStyles?.viewSubHeading}>{viewRecord?.case}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputDocumentPleadingStyles?.viewHeading}>Title</Typography>
                      <Typography sx={inputDocumentPleadingStyles?.viewSubHeading}>{viewRecord?.title}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputDocumentPleadingStyles?.viewHeading}>Date</Typography>
                      <Typography sx={inputDocumentPleadingStyles?.viewSubHeading}>{viewRecord?.createdAt}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputDocumentPleadingStyles?.viewHeading}>Status</Typography>
                      <Typography sx={inputDocumentPleadingStyles?.viewSubHeading}>{viewRecord?.status}</Typography>
                    </Box>
                  </Grid>

                  {viewRecord?.file1?.map((v, i) => (
                    <Grid item xs={12} sx={{ background: "#4A4C64", marginLeft: "16px", borderRadius: "4px", outline: "1px solid #4A4C64", marginBottom: "26px" }}>
                      <Box sx={{ margin: "2px 0 20px 0" }}>
                        <Grid container spacing={2}>
                          {/* Version */}
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#A6A7C1",
                                fontWeight: "500",
                              }}
                            >
                              Version {i + 1}
                            </Typography>
                          </Grid>

                          {/* Sources */}
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#A6A7C1",
                                fontWeight: "500",
                              }}
                            >
                              Sources
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid xs={12}>
                          <Divider color={theme?.palette?.divider} sx={{ height: "0.1%", width: "98%", marginTop: "10px" }} />
                        </Grid>

                        <Grid container spacing={2} sx={{ padding: "10px 0" }}>
                          {/* Source 1 */}
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                lineHeight: "19.8px",
                                color: "#686DFC",
                                cursor: "pointer",
                                textDecoration: "underline",
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                  color: "#686DFC",
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileAction(v, "generated");
                              }}
                            >
                              <Word />
                              Source 1
                            </Typography>
                          </Grid>

                          {/* Icons for Source 1 */}
                          <Grid item xs={12} md={6}>
                            <Box sx={{ cursor: "pointer", display: "flex", gap: "10px", alignItems: "center" }}>
                              <RemoveRedEyeIcon sx={{ width: "20px", color: "#505058" }} onClick={() => handleFileAction(`pleadingGenerate/${viewRecord.id}?source=1&pleading=${i}`, "view")} />
                              <GetAppIcon
                                sx={{ width: "20px", color: "#505058" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileAction(`pleadingGenerate/${viewRecord.id}?source=1&pleading=${i}`, "generated");
                                }}
                              />
                            </Box>
                          </Grid>
                          <FileViewModal />
                        </Grid>
                        <Grid xs={12}>
                          <Divider color="#A6A7C1" sx={{ height: "0.1%", width: "98%", marginTop: "8px ", marginBottom: "10px" }} />
                        </Grid>
                        <Grid container spacing={2}>
                          {/* Source 2 */}
                          <Grid item xs={12} md={6}>
                            <Typography
                              sx={{
                                fontSize: "18px",
                                lineHeight: "19.8px",
                                color: "#686DFC",
                                cursor: "pointer",
                                textDecoration: "underline",
                                display: "flex",
                                alignItems: "center",
                                "&:hover": {
                                  color: "#686DFC",
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFileAction(viewRecord?.file2[i], "generated");
                              }}
                            >
                              <Excel />
                              Source 2
                            </Typography>
                          </Grid>

                          {/* Icons for Source 2 */}
                          <Grid item xs={12} md={6}>
                            <Box sx={{ cursor: "pointer", display: "flex", gap: "10px" }}>
                              <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} onClick={() => handleFileAction(`pleadingGenerate/${viewRecord.id}?source=2&pleading=${i}`, "view")} />
                              <GetAppIcon
                                sx={{ width: "20px", color: "#C8C9DB" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleFileAction(`pleadingGenerate/${viewRecord.id}?source=2&pleading=${i}`, "generated");
                                }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDocumentPleadingDetail;

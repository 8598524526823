import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import BalanceIcon from "@mui/icons-material/Balance";
import FeedIcons from "../../Components/Icons/FeedIcon"; 
import GenerateMedicalForm from "../../Components/Forms/GenerateMedicalForm";
import { theme } from "../../constant/Theme";

const steps = [{ text: "Basic Details", label: "Document  information" }];
const AddNewDocumentMedical = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: "#A6A7C1" }}>
              Medicals /
              <Box component="span" sx={{ color: "#F2F3FB" }}>
                {" "}
                Generate New Medical
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: theme?.palette?.background?.paper,
                color: theme?.palette?.secondary?.main,
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stepper
                    activeStep={activeStep}
                    connector={
                      <ArrowForwardIosIcon
                        sx={{
                          marginLeft: "20px",
                          marginRight: "5px",
                        }}
                      />
                    }
                  >
                    {steps.map((step, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      labelProps.optional = (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#8A8BA1",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {step.label}
                        </Typography>
                      );
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          key={index}
                          {...stepProps}
                          sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "#686DFC",
                            },
                          }}
                        >
                          <StepLabel
                            {...labelProps}
                            icon={
                              step.text == "Basic Details" ? (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    backgroundImage: activeStep >= index ? theme?.palette?.primary?.iconColor : theme?.palette?.primary?.heading,
                                  }}
                                >
                                  <FeedIcons color="white" />
                                </Box>
                              ) : (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "10px",
                                    display: "flex",
                                    width: "40px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "40px",
                                    background: activeStep >= index ? theme?.palette?.primary?.iconColor : theme?.palette?.secondary?.inputColor,
                                  }}
                                >
                                  <BalanceIcon />
                                </Box>
                              )
                            }
                          >
                            {step.text}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Grid item xs={12} style={{ paddingTop: "16px" }}>
                    <Divider sx={{ backgroundColor: theme?.palette?.divider }} />
                  </Grid>

                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <GenerateMedicalForm />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewDocumentMedical;

import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import { theme } from "../../constant/Theme";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  backgroundColor: theme?.palette?.primary?.bodyColor,
  borderRadius: "4px",
  // boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
  // border: "1px solid #686DFC",
};

const popupModal = ({ open, handleClose, cards, styleProp }) => {
  const finalStyle = styleProp && Object.keys(styleProp)?.length > 0 ? styleProp : style;
  return (
    <Grid container justifyContent="center" sx={{ width: "100%" }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box
            sx={{
              height: "500px",
              overflow: "scroll",
              scrollbarWidth: "none",
              ...finalStyle,
            }}
          >
            {cards}
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default popupModal;

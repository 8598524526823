import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#2B2C40", // heading color
      placeholderColor: "#505058", //for the placeholder
      subPrimary: "#8A8BA1",
      subSecondary: "#F4F8FF", //for the background shahow
      tertiaryShades: "#D0E2FF",
      bodyColor: "#FFFFFF",
      secondaryheading: "#F2F3FB", // secondary heading color
      subscriptionHeading: "#FFFFFF",
      backgroundSidebar: "#E6E7F2", //used in the main layout
      sidebar: "#F9FAFB",
      heading: "#686DFC",
      iconColor: "linear-gradient(to  bottom, #AB82F9, #696CFF)",
      tertiaryHeading: "#E6E7F2",
      dropDownColor: "#b6b6b9",
      navBg: "#E4EBF6",
      btnPrimary: "#387CFF", //using for the select dropdown
      loginBackground: "#f9fafb",
      textColor: "#2b2c40",
    },
    secondary: {
      main: "#C8C9DB", //input field color and aslo heading
      secondaryMain: "#5C94FE", //
      secondary: "#8D43FE",
      sidebarHeading: "#2B2C40",
      dangerMain: "#D32F2F",
      inputColor: "#BDBEDC", //input color of all
      // inputColor: "#4A4C64",
      hoverColor: "#505058", //used for the input color
      // PrimarybackgroundColor: "#F4F8FF",
      cancelBtn: "#F5EEFF", //cancel background btn
      btnPrimary: "#387CFF", //used for the btn primary
      btnSecondary: "#8D43FE", //used for the btn cancel primary color
      //used for the btn cancel primary color
    },
    background: {
      paper: "#FFFFFF",
    },
    //   here defind the divider
    divider: "#E6E7F2",
  },

  typography: {
    // fontFamily: "IBM, sans-serif", // Default for body text
    fontFamily: '"IBM Plex Sans", sans-serif',

    title: {
      fontFamily: "IBM-Medium",
      fontSize: "28px",
      fontWeight: 500,
    },
    subheading: {
      fontFamily: "IBM-Medium",
      fontSize: "20px",
      fontWeight: 500,
    },
    tabsHeading: { fontFamily: "IBM-Medium", fontSize: "14px", fontWeight: 500 },

    inputHeading: {
      fontFamily: "IBM-Medium",
      fontSize: "16px",
      fontWeight: 500,
    },
    inputPlaceHolder: {
      fontFamily: "IBM-Regular",
      fontSize: "18px",
      fontWeight: 400,
    },
    bodyText: {
      fontFamily: "IBM-Regular",
      fontSize: "16px",
      fontWeight: 400,
    },
    smallText: {
      fontFamily: "IBM-Regular",
      fontSize: "14px",
      fontWeight: 600,
    },
    smallHeading: {
      fontFamily: "IBM, sans-serif",
      fontSize: "14px",
      fontWeight: 400,
    },
    minorText: {
      fontFamily: "IBM-Regular",
      fontSize: "16px",
      fontWeight: 300,
    },
    dashboardButtonText: {
      fontFamily: "IBM-Regular",
      fontSize: "14px",
      fontWeight: 700,
    },
    dashboardHeadingText: {
      fontFamily: "IBM-Medium",
      fontSize: "16px",
      fontWeight: 500,
    },
    dashboardHeadingSubText: {
      fontFamily: "IBX-Regular",
      fontSize: "14px",
      fontWeight: 500,
    },
    cardsTitle: {
      fontFamily: "IBM-Medium",
      fontSize: "24px",
      fontWeight: 500,
    },
    cardsTitle: {
      fontFamily: "IBM-Medium",
      fontSize: "24px",
      fontWeight: 500,
    },
    cardsTitle2: {
      fontFamily: "IBM-Regular",
      fontSize: "24px",
      fontWeight: 500,
    },

    cardSubheading: {
      fontFamily: "IBM-Regular",
      fontSize: "18px",
      fontWeight: 500,
    },
    minorText: {
      fontFamily: "IBM-Regular",
      fontSize: "16px",
      fontWeight: 300,
    },
    placeHolder: {
      fontFamily: "IBX-Regular",
      fontSize: "16px",
      fontWeight: 500,
    },
  },

  shadows: {
    0: "0px 4px 8px rgba(104, 109, 252, 0.5)",
    1: "0px 6px 12px rgba(104, 109, 252, 0.7)",
  },
});

import React from "react";
import { Autocomplete, Box, Checkbox, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, SvgIcon, TextField, Typography } from "@mui/material";
import { allCategory } from "../../../constant/GeneratePleading";
import ClearIcon from "@mui/icons-material/Clear";
import { styles } from "../../../pages/Queries/Style";
import useQuerieDropdownHook from "./useQuerieDropdownHook";
import useChatMessages from "../../../hooks/useChatMessages";
import { theme } from "../../../constant/Theme";

const QuerieDropdwon = ({ formik, chatHistory }) => {
  const { allCase, allPersons, userFile, allType, querierCondtion, isCasesLoading, isPersonsLoading, isFilesLoading } = useQuerieDropdownHook(formik);
  const { isLoading, allChat: data, newMessageId } = useChatMessages();

  const handleFillingPartySelect = (value) => {
    if (value === formik.values.fillingParty) {
      // Clear the value if the same fillingParty is clicked
      formik.setFieldValue("fillingParty", "");
      formik.setFieldValue("personsId", "");
      formik.setFieldValue("witnessData", null);
    }
  };
  const handleWitnessSelect = (value) => {
    // const filterData = allPersons?.find((v) => v.personsId === value)?.witnessData;

    if (value === formik.values.fillingParty) {
      // If the same value is clicked, clear it
      formik.setFieldValue("fillingParty", "");
      formik.setFieldValue("personsId", "");
      formik.setFieldValue("witnessData", null);
    }
  };
  const handleMenuItemClick = (value) => {
    if (value === formik.values.category) {
      // If the same value is clicked, clear it
      formik.setFieldValue("category", []);
    }
  };
  const handleTypeSelect = (value) => {
    if (value === formik.values.type) {
      formik.setFieldValue("type", "");
    }
  };
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    formik.setFieldValue("category", value);
    formik.setFieldValue("items", []);
    formik.setFieldValue("personsId", "");

    if (value === "client") {
      formik.setFieldValue("sources", 1);
    } else if (value === "medical") {
      formik.setFieldValue("sources", 0); // Disable or reset sources when medical is selected
    }
  };
  const MenuProps = {
    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  function removeIdAndAll(array, idToRemove) {
    // Create a new array without the specified ID and "all"
    return array.filter((id) => id !== idToRemove && id !== "all");
  }

  return (
    <Grid item xs={12} style={{ paddingTop: "0" }}>
      <Box component="form" noValidate onSubmit={formik.handleSubmit}>
        {/* Category Dropdown */}
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={3}>
            <FormControl variant="outlined" size="small" fullWidth error={formik.touched.case && Boolean(formik.errors.case)} sx={styles?.input}>
              <Autocomplete
                id="case"
                multiple
                options={allCase}
                getOptionLabel={(option) => option.title}
                autoComplete
                size="small"
                includeInputInList
                value={
                  formik.values.caseId?.length === allCase.length
                    ? [{ _id: "all", title: "All" }] // Show "All" if all cases are selected
                    : allCase?.filter((option) => formik.values.caseId?.includes(option._id))
                }
                onChange={(event, newValue) => {
                  formik.setFieldValue("category", []);
                  formik.setFieldValue("personsId", []);
                  formik.setFieldValue("filter", []);
                  const selectedIds = newValue.map((option) => option._id);
                  const isAllSelected = selectedIds.includes("all");
                  const previouslySelected = formik.values.caseId || [];

                  if (isAllSelected) {
                    const allCaseIds = allCase.map((option) => option._id);
                    // Check if "All" was already selected
                    const previouslyIncludesAll = previouslySelected.includes("all");
                    if (isAllSelected && newValue[1]?._id !== "all" && newValue.length > 1) {
                      let caseIds = formik.values.caseId;
                      let arr = removeIdAndAll(caseIds, newValue[1]?._id);
                      formik.setFieldValue("caseId", arr);
                    }
                    if (previouslyIncludesAll && newValue[1]?._id === "all") {
                      // If "All" was previously selected, deselect all
                      formik.setFieldValue("caseId", []);
                    } else if (newValue[newValue.length - 1]?._id === "all") {
                      formik.setFieldValue("caseId", allCaseIds);
                    }
                  } else {
                    // If "All" is not selected, just update with selected IDs
                    formik.setFieldValue("caseId", selectedIds);
                  }
                }}
                onBlur={formik.handleBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Matters"
                    variant="outlined"
                    fullWidth
                    error={formik.touched.case && Boolean(formik.errors.case)}
                    helperText={formik.touched.case && formik.errors.case ? formik.errors.case : ""}
                    InputLabelProps={{
                      sx: {
                        color: theme?.palette?.primary?.main,
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                        "& fieldset": {
                          borderColor: theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover fieldset": {
                          borderColor: theme?.palette?.secondary?.hoverColor,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: theme?.palette?.primary?.inputColor,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => {
                  const isAllSelected = formik.values.caseId?.length === allCase.length;

                  return (
                    <li {...props}>
                      <Checkbox
                        checked={selected || isAllSelected}
                        // checked={selected || (option._id === "all" && isAllSelected)}

                        sx={{
                          color: isAllSelected ? theme?.palette?.primary?.placeholderColor : theme?.palette?.primary?.placeholderColor,
                          backgroundColor: "transparent",
                          "&.Mui-checked": {
                            color: isAllSelected ? "#686DFC" : "#686DFC",
                            backgroundColor: "transparent",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.2rem",
                          },
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: isAllSelected ? theme?.palette?.primary?.placeholderColor : theme?.palette?.primary?.placeholderColor,
                        }}
                        paddingLeft={1}
                      >
                        {option.title}
                      </Typography>
                    </li>
                  );
                }}
                PaperComponent={(props) => (
                  <Paper
                    {...props}
                    sx={{
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor,
                    }}
                  />
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      display: "flex",
                      alignItems: "center",
                      "&:hover": {
                        backgroundColor: theme?.palette?.primary?.tertiaryShades,
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "red",
                      textAlign: "center",
                    },
                  },
                }}
                renderTags={(value, getTagProps) => {
                  const maxVisibleChips = 1;
                  if (formik.values.caseId?.length === allCase.length) {
                    // Show "All" tag if all are selected
                    return (
                      <Chip
                        label="All"
                        deleteIcon={
                          <SvgIcon
                            sx={{
                              color: theme?.palette?.primary?.placeholderColor,
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: theme?.palette?.primary?.placeholderColor,
                              }}
                            />
                          </SvgIcon>
                        }
                        sx={{
                          height: "25px",
                          backgroundColor: theme?.palette?.primary?.tertiaryShades,
                          color: theme?.palette?.primary?.placeholderColor,
                          textTransform: "capitalize",
                          borderRadius: "2px",
                          width: "45px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize: "0.875rem",
                        }}
                      />
                    );
                  }

                  const extraCount = value.length - maxVisibleChips;
                  return (
                    <>
                      {value.slice(0, maxVisibleChips).map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option?.title}
                          deleteIcon={
                            <SvgIcon
                              sx={{
                                color: "#686DFC",
                                width: "15px",
                                height: "15px",
                              }}
                            >
                              <ClearIcon
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  color: "#686DFC",
                                }}
                              />
                            </SvgIcon>
                          }
                          sx={{
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                            height: "25px",
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            width: "100px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.875rem",
                          }}
                        />
                      ))}
                      {extraCount > 0 && (
                        <Chip
                          label={`${extraCount} more`}
                          sx={{
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            width: "45px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "0.875rem",
                          }}
                        />
                      )}
                    </>
                  );
                }}
                noOptionsText="No matter found"
              />
            </FormControl>
          </Grid>
          {/* category */}
          <Grid item sm={3}>
            <FormControl
              fullWidth
              variant="outlined"
              size="small"
              error={formik.touched.category && Boolean(formik.errors.category)}
              sx={{ ...styles?.input, backgroundColor: formik.values.caseId[0] === undefined ? theme?.palette?.primary?.subSecondary : "" }}
            >
              <InputLabel
                id="category-label"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor, // Set text color

                  "&.Mui-focused": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                  "&.Mui-disabled": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                Category
              </InputLabel>
              <Select
                labelId="category-label"
                id="category"
                name="category"
                label="Category"
                disabled={formik.values.caseId[0] === undefined}
                value={formik.values.category || ""}
                onBlur={formik.handleBlur}
                onChange={handleCategoryChange}
                sx={{
                  "&.Mui-disabled": {
                    color: theme?.palette?.secondary?.inputColor,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor,
                  },
                  "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                    borderColor: theme?.palette?.secondary?.inputColor,
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                  "&.Mui-disabled .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor, // Set text color

                      maxHeight: 200,
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: theme?.palette?.primary?.tertiaryShades,
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <Typography
                    sx={{
                      fontStyle: "normal",
                      color: theme?.palette?.primary?.dropDownColor,
                    }}
                  >
                    Select Category
                  </Typography>
                </MenuItem>
                {allCategory
                  ?.sort((a, b) => a.title.localeCompare(b.title)) // Sort by title
                  .map((value, index) => (
                    <MenuItem value={value.values} key={index} onClick={() => handleMenuItemClick(value?.values)}>
                      {value.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          {/* person */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.fillingParty && Boolean(formik.errors.fillingParty)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                    },
                  }}
                >
                  Persons
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="Persons"
                  value={formik.values.fillingParty || ""} // Ensure this is the correct field
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    const filterData = allPersons.find((v) => v.personsId === e.target.value)?.witnessData;

                    formik.setFieldValue("witnessData", filterData);
                    formik.setFieldValue("fillingParty", selectedValue);
                    formik.setFieldValue("personsId", selectedValue);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                  renderValue={(selected) => {
                    // Check if a person is selected
                    if (selected) {
                      const selectedPerson = allPersons?.find((person) => person.personsId === selected);
                      return selectedPerson ? selectedPerson.personName : <em>Select person</em>;
                    }
                    return <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Person</Typography>; // Default placeholder
                  }}
                  sx={{
                    // Add these styles to handle long text
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography
                      sx={{
                        fontStyle: "normal",
                        color: theme?.palette?.primary?.dropDownColor,
                      }}
                    >
                      Select Person
                    </Typography>
                  </MenuItem>
                  {allPersons?.map((row, index) => (
                    <MenuItem key={index} value={row.personsId} onClick={() => handleFillingPartySelect(row.personsId)}>
                      {`${row?.personName}`}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && <FormHelperText>{formik.errors.fillingParty}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(formik.values.category) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched?.type && Boolean(formik.errors?.type)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor,
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="type-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    // Change label color
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                    },
                  }}
                >
                  {formik.values.category === "person" ? "Role" : "Type"}
                </InputLabel>
                <Select
                  labelId="type-label"
                  id="type"
                  label="Type"
                  autoComplete="off"
                  name="type"
                  placeholder="type"
                  value={formik.values?.type}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                    MenuProps,
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select {formik.values.category === "person" ? "Role" : "Type"}</Typography>{" "}
                  </MenuItem>
                  {allType?.map((row, index) => (
                    <MenuItem key={index} value={row} onClick={() => handleTypeSelect(row)}>
                      {row?.length >= 25 ? row.substring(0, 25) + "..." : row}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.type && formik.errors.type && <FormHelperText>{formik.errors.type}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  backgroundColor: formik.values.category === "client" || formik.values.caseId[0] === undefined || formik?.values?.category ? theme?.palette?.primary?.subSecondary : "",
                }}
              >
                <InputLabel
                  id="filter-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,

                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                >
                  Filter 1
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled
                  label="Filter 1"
                  value={formik.values.personsId || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                />
              </FormControl>
            </Grid>
          )}
          {/* Item Dropdown */}
          {formik.values.category === "depositions" ? (
            <Grid item sm={3}>
              <FormControl fullWidth variant="outlined" size="small" error={formik.touched.items && Boolean(formik.errors.items)} sx={styles?.input}>
                <Autocomplete
                  size="small"
                  multiple
                  disableClearable
                  id="items-autocomplete"
                  options={userFile} // The array of files with fileName and fileUrl
                  getOptionLabel={(option) => option?.fileName || ""} // Display fileName in dropdown
                  value={formik.values.items ? userFile.filter((file) => formik.values.items.includes(file.fileUrl)) : []}
                  onChange={(event, newValue) => {
                    const fileUrls = newValue.map((item) => item?.fileUrl);
                    formik.setFieldValue("items", fileUrls);
                  }}
                  disableCloseOnSelect // Keep dropdown open while selecting multiple options
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Items"
                      name="items"
                      error={formik.touched.items && Boolean(formik.errors.items)}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{
                        style: {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                      sx={{
                        color: theme?.palette?.primary?.placeholderColor,
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "5px",
                          width: "100%",
                          color: theme?.palette?.primary?.placeholderColor,
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.secondary?.inputColor,
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme?.palette?.secondary?.hoverColor,
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                        "&.Mui-focused": {
                          color: theme?.palette?.primary?.placeholderColor,
                        },
                      }}
                    />
                  )}
                  PaperComponent={({ children }) => (
                    <Paper
                      sx={{
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color
                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      }}
                    >
                      {children}
                    </Paper>
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={option?.fileName} // Display fileName in tags
                        deleteIcon={
                          <SvgIcon
                            sx={{
                              color: theme?.palette?.primary?.placeholderColor,
                              width: "15px",
                              height: "15px",
                            }}
                          >
                            <ClearIcon
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: theme?.palette?.primary?.placeholderColor,
                              }}
                            />
                          </SvgIcon>
                        }
                        sx={{
                          backgroundColor: theme?.palette?.primary?.subSecondary,
                          color: theme?.palette?.primary?.placeholderColor,
                          textTransform: "capitalize",
                          borderRadius: "2px",
                          width: "70px",
                          whiteSpace: "nowrap", // Prevent the text from wrapping
                          overflow: "hidden", // Hide any overflow content
                          textOverflow: "ellipsis", // Add ellipsis for long text
                          fontSize: "0.875rem",
                        }}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      key={option.id}
                      style={{
                        color: theme?.palette?.primary?.placeholderColor,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        checked={selected}
                        sx={{
                          color: theme?.palette?.primary?.placeholderColor,
                          width: "30px",
                          height: "30px",
                          backgroundColor: "transparent",
                          "&.Mui-checked": {
                            color: "#686DFC",
                            backgroundColor: "transparent",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: "1.2rem", // Adjust this value for width/height
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: "14px" }} paddingLeft={1}>
                        {" "}
                        {option.fileName}
                      </Typography>
                    </li>
                  )}
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "& .MuiOutlinedInput-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                      "&.Mui-focused fieldset": {
                        borderColor: theme?.palette?.primary?.placeholderColor,
                      },
                    },
                    "& .MuiAutocomplete-popupIndicator": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiAutocomplete-clearIndicator": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiListSubheader-root, .MuiAutocomplete-option": {
                      backgroundColor: "#323249",
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                />
                {formik.touched.items && formik.errors.items && <FormHelperText>{formik.errors.items}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : querierCondtion.includes(formik.values.category || chatHistory?.moduleName) && (formik.values?.type || chatHistory?.type) ? (
            <Grid item sm={3}>
              <FormControl
                variant="outlined"
                size="small"
                fullWidth
                error={formik.touched.fillingParty && Boolean(formik.errors.fillingParty)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor, // Border color on focus
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              >
                <InputLabel
                  id="fillingParty-label"
                  sx={{
                    color: theme?.palette?.primary?.main,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.main, // Change label color when focused
                    },
                  }}
                >
                  Witness Name
                </InputLabel>
                <Select
                  labelId="fillingParty-label"
                  id="fillingParty"
                  autoComplete="off"
                  name="fillingParty"
                  label="witness name"
                  value={formik.values.personsId || chatHistory?.person_id || formik.values.fillingParty}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const filterData = allPersons?.find((v) => v.personsId === e.target.value)?.witnessData;
                    formik.setFieldValue("fillingParty", e.target.value);
                    formik.setFieldValue("personsId", e.target.value);
                    formik.setFieldValue("witnessData", filterData);
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Witness Name</Typography>
                  </MenuItem>
                  {allPersons?.map((row, index) => (
                    <MenuItem key={index} value={row.personsId} onClick={() => handleWitnessSelect(row.personsId)}>
                      {`${row?.personName}`}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.fillingParty && formik.errors.fillingParty && <FormHelperText>{formik.errors.fillingParty}</FormHelperText>}
              </FormControl>
            </Grid>
          ) : (
            <Grid item sm={3}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  backgroundColor:
                    formik.values.category === "client" ||
                    formik.values.category === "discovery" ||
                    formik.values.category === "exhibit" ||
                    formik.values.category === "medical" ||
                    formik.values.category === "person" ||
                    formik?.values?.category === "pleading" ||
                    formik.values.caseId[0] === undefined ||
                    formik?.values?.category
                      ? theme?.palette?.primary?.subSecondary
                      : "",
                }}
              >
                <InputLabel
                  id="filter-label"
                  sx={{
                    color: theme?.palette?.primary?.placeholderColor,
                    "&.Mui-focused": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                >
                  Filter 2
                </InputLabel>
                <Select
                  labelId="filter-label"
                  id="filter"
                  name="filter"
                  disabled
                  label="filter 2"
                  value={formik.values.filter || ""}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  sx={{
                    "&.Mui-disabled": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "& .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                    "&.Mui-disabled .MuiSvgIcon-root": {
                      color: theme?.palette?.primary?.placeholderColor,
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: theme?.palette?.primary?.subSecondary,
                        color: theme?.palette?.primary?.placeholderColor, // Set text color

                        maxHeight: 200,
                        overflowY: "auto",
                        "& .MuiMenuItem-root": {
                          "&:hover": {
                            backgroundColor: theme?.palette?.primary?.tertiaryShades,
                            color: theme?.palette?.primary?.placeholderColor,
                          },
                        },
                      },
                    },
                  }}
                ></Select>
              </FormControl>
            </Grid>
          )}

          {/* Autocomplete */}

          <Grid item sm={3}>
            <FormControl
              fullWidth
              sx={{
                ...styles?.inpuDisable,
                backgroundColor: formik.values.category === "client" || formik.values.category === "person" || formik?.values?.category === "medical" ? theme?.palette?.primary?.subSecondary : "",
              }}
            >
              <Autocomplete
                sx={{ color: theme?.palette?.primary?.placeholderColor }}
                id="sources"
                disabled={formik.values.category === "client" || formik.values.category === "person" || formik.values.category === "medical"}
                disableClearable={true}
                options={data.length > 0 ? [0, 1, 2, 3, 4, 5, 6, 7, 8] : [1, 2, 3, 4, 5, 6, 7, 8]}
                getOptionLabel={(option) => `${option}`}
                value={formik.values.category === "person" ? 1 : formik.values.sources !== undefined && formik.values.sources !== "" ? formik.values.sources : 4}
                onChange={(event, value) => {
                  formik.setFieldValue("sources", value);
                  console.log("sources", formik.values.sources);
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label="Number of Sources"
                    error={formik.touched.sources && Boolean(formik.errors.sources)}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                        width: "100%",
                        color: theme?.palette?.primary?.placeholderColor,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.inputColor,
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor:
                            formik.values.category === "client" || formik.values.category === "person" || formik?.values?.category === "medical" ? "" : theme?.palette?.primary?.placeholderColor,
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme?.palette?.secondary?.inputColor, // Normal border color
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: theme?.palette?.primary?.placeholderColor,
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                      "&.Mui-focused": {
                        color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: theme?.palette?.primary?.placeholderColor,
                      },
                    }}
                  />
                )}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: theme?.palette?.primary?.subSecondary,
                      color: theme?.palette?.primary?.placeholderColor, // Set text color
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderOption={(props, option) => (
                  <li {...props} key={option} style={{ color: theme?.palette?.primary?.placeholderColor }}>
                    {option}
                  </li>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item sm={3}>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                size="small"
                label="Max Number of Tokens"
                name="maxTokens"
                value={formik.values.maxTokens}
                // value={1000}
                onChange={formik.handleChange} // Ensure onChange is from formik
                sx={{
                  color: theme?.palette?.primary?.placeholderColor,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "5px",
                    width: "100%",
                    backgroundColor: "",

                    color: theme?.palette?.primary?.placeholderColor, // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.hoverColor,
                    },
                    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme?.palette?.secondary?.inputColor,
                    },
                    "&.Mui-disabled .MuiInputBase-input": {
                      color: `${theme?.palette?.primary?.main} !important`,
                      "-webkit-text-fill-color": `${theme?.palette?.primary?.main} !important`,
                    },
                  },
                  "& .MuiSvgIcon-root": {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                  "&.Mui-focused": {
                    color: theme?.palette?.primary?.placeholderColor, // Change label color when focused
                  },
                  "&.Mui-disabled": {
                    color: theme?.palette?.primary?.placeholderColor, // Text color for the disabled state
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: theme?.palette?.primary?.placeholderColor,
                  },
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default QuerieDropdwon;

import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material";
import { useAxios } from "../../../services/http.service";
import notificationService from "../../../services/notification.service";
import { useQueryClient } from "react-query";
import axios from "axios";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import { theme } from "../../../constant/Theme";
import { FilterCancelBtn } from "../../Button";
import { useSelector } from "react-redux";
import { fetchTokenAndPostChatbot } from "../../../services/http.chatbot.service";
const QueriesConfirmationModel = ({ closeModal, threadData }) => {
  const { chatHistory, setChatHistory, setAllChat, matter } = useMultiFileUpload();
  const { user } = useSelector((state) => state.reducer);
  const { deleteRequest } = useAxios();
  const queryClient = useQueryClient();
  console.log(user,'useruseruseruser')
  const handleSubmit = async () => {
   
          
    try {
      const result = await fetchTokenAndPostChatbot(`/organization/delete-thread/${threadData?.thread_id}`,{
        API_KEY: user?.organizationData?.API_KEY || "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        thread_id: threadData?.thread_id
      })
      // Send DELETE request to delete the thread by threadId
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}thread/${threadData?.thread_id}`);

      if (response) {
   
        
     
         queryClient.invalidateQueries("thread");
        setChatHistory(null);
        setAllChat([]);
        notificationService.success("Thread has been deleted successfully.");
      } 
    } catch (error) {
      console.error("Error deleting thread:", error);

      notificationService.error("Failed to delete the thread.");
    }
    closeModal();
  };

  return (
    <Box onClose={closeModal}>
      <Grid container spacing={{ xs: 0, sm: 1 }}>
        <Grid item xs={11} sm={11}>
          <Typography variant="h4" sx={{ ...theme?.typography?.title, fontFamily: theme?.palette?.primary?.main, textAlign: "center" }}>
            Are you sure?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
          <Typography variant="p" color={theme?.palette?.primary?.placeholderColor} sx={{ mt: 2 }}>
            Are you sure you want to delete?{" "}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: 1,
          }}
        >
          <FilterCancelBtn text={"Cancel"} onClick={closeModal} />

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "150px" },
              mt: { xs: 1, sm: 1.5 },
              mb: { xs: 0, sm: 2 },
              height: "45px",
              "&:hover, &:focus": {
                boxShadow: theme?.shadows?.[1],
                backgroundColor: "white",
                color: "#FFFFFF",
                color: "#FFFFFF",
                backgroundColor: theme?.palette?.secondary?.dangerMain,
                boxShadow: 0,
              },

              boxShadow: 0,
              fontSize: "12px",
              backgroundColor: theme?.palette?.secondary?.dangerMain,
              fontWeight: "600",
              fontFamily: theme?.typography?.fontFamily,
              ...theme?.typography?.smallText,
            }}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QueriesConfirmationModel;

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { Grid, TextField, Button, Autocomplete, FormControl, Select, MenuItem, InputLabel, FormHelperText, Paper, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service";
import { state } from "../../../constant/ConstantStateData";
import { getInputCourtStyle } from "../../../pages/Courts/style";
import { theme } from "../../../constant/Theme";
import { NewAddHeading } from "../../Card/SettingCard";
import FeedIcons from "../../Icons/FeedIcon";
import LabeledTextField from "../../../constant/ConstantInputField";
import { ButtonUICancel } from "../../Button";
const UpdateCourtForm = () => {
  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const dispatch = useDispatch();
  let stateNames = state;
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const { userData } = location.state || {};

  const queryClient = useQueryClient();
  const courtData = userData;
  const { setBearerToken, put } = useAxios();
  const setClientData = async (newData) => {
    setBearerToken(token);
    const response = await put(`/court`, newData);
    return response.data;
  };

  const mutation = useMutation(setClientData, {
    onSuccess: () => {
      showSuccessToastMessage("Record is successfull updated!");
      navigate("/courts");
      queryClient.invalidateQueries("courtData");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });
  const formik = useFormik({
    initialValues: {
      courtId: courtData?._id,
      name: courtData?.name || "",
      type: courtData?.type || "",
      address: courtData?.address || "",
      city: courtData?.city || "",
      state: courtData?.state || "",
      zipCode: courtData?.zipCode || "",
      stateOf: courtData?.stateOf || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string(),
      address: Yup.string().min(10, "Street Address must be at least 10 characters"),
      city: Yup.string().matches(/^[A-Za-z\s]+$/, "City must contain only letters and spaces."),
      state: Yup.string(),
      zipCode: Yup.string().matches(/^\d{5}$/, "Zip code must be at least 5 characters."),
    }),

    onSubmit: async (values, { resetForm }) => {
      mutation.mutate(values);
    },
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <NewAddHeading title="Basic Details" subTitle="Court information" icon={<FeedIcons color="#387CFF" />} />

      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 2, position: "relative", p: 3 }} onKeyDown={handleKeyDown}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LabeledTextField
              formik={formik}
              name="name"
              label=" Court Name "
              placeholder="E.g., UNITED STATES SUPREME COURT"
              required={true}
              sx={getInputCourtStyle?.courtName(formik)}
              InputProps={{
                style: {
                  color: theme?.palette?.primary?.main,
                  fontFamily: theme?.typography?.fontFamily,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LabeledTextField
              formik={formik}
              name="stateOf"
              label="State Of"
              placeholder="E.g., STATE OF CALIFORNIA"
              sx={getInputCourtStyle?.inputText}
              InputProps={{
                style: {
                  color: theme?.palette?.primary?.main,
                  fontFamily: theme?.typography?.fontFamily,
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography
              variant="body1"
              sx={{
                color: theme?.palette?.primary?.main,
                marginBottom: "4px",
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              Court Type
            </Typography>
            <FormControl variant="outlined" size="small" fullWidth error={formik.touched.type && Boolean(formik.errors.type)} sx={getInputCourtStyle?.inputText}>
              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                displayEmpty
                name="type"
                value={formik.values.type}
                onBlur={formik.handleBlur}
                placeholder="Enter Court Type"
                onChange={(e) => {
                  formik.setFieldValue("type", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#FFFFFF",
                      fontFamily: theme?.typography?.fontFamily,
                      color: theme?.palette?.primary?.main,
                      maxHeight: 200,
                      overflowY: "auto",
                      "& .MuiMenuItem-root": {
                        "&:hover": {
                          backgroundColor: "#686DFC26",
                          color: "#686DFC",
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  {/* Select court type */}
                  <Typography sx={{ fontStyle: "normal", color: theme?.palette?.primary?.dropDownColor }}>Select Court Type</Typography>
                </MenuItem>
                {[
                  "Supreme Court of the United States",
                  "United States Courts of Appeals (Circuit Courts)",
                  "United States District Courts",
                  "United States Court of International Trade",
                  "United States Tax Court",
                  "Military Courts (Courts-Martial)",
                  "State Courts",
                  "Specialized Courts",
                  "Tribal Courts",
                ]
                  ?.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                  ?.map((value, index) => {
                    return (
                      <MenuItem key={index} value={value}>
                        {value}
                      </MenuItem>
                    );
                  })}
              </Select>
              {formik.touched.type && formik.errors.type && <FormHelperText>{formik.errors.type}</FormHelperText>}
            </FormControl>
          </Grid>
          {/* <Grid sm={6}></Grid> */}
          <Grid item xs={12} sm={6}>
            <LabeledTextField formik={formik} name="address" label="Street Address" placeholder="Enter  Street Address" sx={getInputCourtStyle?.inputText} />
          </Grid>

          <Grid item xs={6} sm={4}>
            <LabeledTextField formik={formik} name="city" label="City" placeholder="Enter City" sx={getInputCourtStyle?.inputText} />
          </Grid>

          <Grid item xs={6} sm={4}>
            <Typography
              variant="body1"
              sx={{
                color: formik.touched.state && Boolean(formik.errors?.state) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.primary?.main,
                marginBottom: "4px", // Spacing between label and input
                fontFamily: theme?.typography?.fontFamily,
                ...theme?.typography?.inputHeading,
              }}
            >
              State
            </Typography>
            <Autocomplete
              id="state"
              disableClearable={true}
              options={stateNames}
              getOptionLabel={(option) => option}
              value={formik.values.state || ""}
              onChange={(event, newValue) => {
                formik.setFieldValue("state", newValue);
              }}
              onBlur={formik.handleBlur}
              PaperComponent={({ children }) => (
                <Paper
                  style={{
                    backgroundColor: "#FFFFFF",
                    color: theme?.palette?.primary?.main,
                    maxHeight: 300, // Adjust as needed
                    overflowY: "auto",
                  }}
                >
                  {children}
                </Paper>
              )}
              ListboxProps={{
                sx: {
                  maxHeight: 250,
                  overflowY: "auto",
                  "& .MuiAutocomplete-option": {
                    "&:hover": {
                      backgroundColor: "#686DFC26",
                      color: "#686DFC",
                    },
                  },
                  "& .MuiAutocomplete-noOptions": {
                    color: "#FF7799", // Customize the color of the noOptionsText
                  },
                },
              }}
              noOptionsText="No cases found"
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik.touched.state && formik.errors?.state}
                  id="state"
                  name="state"
                  variant="outlined"
                  size="small"
                  autoComplete="state"
                  placeholder="Select State"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  helperText={formik.touched?.state && formik.errors?.state}
                  sx={getInputCourtStyle?.inputText}
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      color: theme?.palette?.primary?.main,
                      ...theme?.typography?.inputPlaceHolder,
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <LabeledTextField formik={formik} name="zipCode" label="Zip Code" placeholder="Enter Zip Code" sx={getInputCourtStyle?.inputText} />
          </Grid>
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button type="submit" variant="contained" sx={getInputCourtStyle?.submit}>
              Update
            </Button>

            <ButtonUICancel text={"Cancel"} onClick={() => navigate("/courts")} variant={"contined"} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateCourtForm;

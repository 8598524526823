import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Paper, TableBody, TableCell, TableRow, Typography, TableHead, Table, TableContainer, Stack } from "@mui/material";
import Pagination from "../../Components/Pagination/Pagination";
 import { useLocation, useNavigate } from "react-router-dom";
import useStaticFormattedDate from "../../hooks/useStaticDate";
import { inputPersonStyles } from "./style";
import { theme } from "../../constant/Theme";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useQuery } from "react-query";
import { useAxios } from "../../services/http.service";
import { useSelector } from "react-redux";
import { PaperComponent } from "../../Components/Card/SettingCard";
import { BackBtn } from "../../Components/Button";
// Function to create row data

const ViewPersonMatterDetails = () => {
  const location = useLocation();
  const { viewRecord } = location.state || {};
  const [openRow, setOpenRow] = React.useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { user } = useSelector((state) => state.reducer);
  const navigate = useNavigate();
  const { date } = useStaticFormattedDate(viewRecord?.createdAt);
  const { get, setBearerToken } = useAxios();

  const fetchPersonData = async () => {
    if (!viewRecord?._id) {
      return;
    }
    const token = user?.userData?.token;
    setBearerToken(token);
    const endpoint = `/personSignle/${viewRecord._id}?page=${currentPage}&limit=${limit}`;
    const response = await get(endpoint);

    return response.data.data;
  };

  const { data, isLoading } = useQuery(
    ["pleadingsData", currentPage, limit],
    fetchPersonData, // Pass function reference, not invocation
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  console.log(viewRecord, "datadatadata");
  // State to manage which row is open
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PaperComponent>
              <Box sx={inputPersonStyles?.viewPaper}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        color: theme?.palette?.primary?.main,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "600",
                          lineHeight: "33.6px",
                          fontSize: "28px !important",
                          textTransform: "capitalize",
                        }}
                      >
                        {" "}
                        Person Details
                      </Typography>

                      <BackBtn title={"back"} link={"/persons"} />
                    </Box>
                  </Grid>
                </Grid>
                {/* here is defin it  */}
                <Grid
                  container
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  ></Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Prefix</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.gender ? viewRecord?.gender : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>First Name</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.firstName ? viewRecord?.firstName : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Last Name</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.lastName ? viewRecord.lastName : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Suffix</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.suffix ? viewRecord?.suffix : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Email</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.email ? viewRecord?.email : "N/A"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Company Name</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.companyName ? viewRecord?.companyName : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Phone No</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.phone ? viewRecord?.phone : "N/A"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Address</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.address ? viewRecord?.address : "N/A"}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>City</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.city ? viewRecord?.city : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>State</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.state ? viewRecord?.state : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Zip Code</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}>{viewRecord?.zipcode ? viewRecord?.zipcode : "N/A"}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Served with Pleadings</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}></Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography sx={inputPersonStyles?.viewHeading}>Deposition Required</Typography>
                      <Typography sx={inputPersonStyles?.viewSubHeading}></Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box>
                      <Typography sx={{ ...inputPersonStyles?.viewHeading, display: "flex", gap: "7px" }}>
                        Matters of{" "}
                        <Typography sx={{ ...inputPersonStyles?.viewHeading, textTransform: "capitalize" }}>
                          {" "}
                          {viewRecord?.firstName ? `${viewRecord?.firstName} ${viewRecord?.lastName}` : viewRecord?.companyName}{" "}
                        </Typography>
                      </Typography>
                      {/* {data?.person?.firstName ? `${data.person.firstName} ${data?.person?.lastName}` : data?.person?.companyName} */}
                    </Box>
                  </Grid>

                  {/* here is table  */}
                  <Grid item xs={12} md={12}>
                    <Box sx={{ width: "100%", overflow: "hidden", border: "1px solid #E6E7F2" }}>
                      <TableContainer>
                        <Table aria-label="collapsible table">
                          <TableHead sx={{ background: theme?.palette?.primary?.sidebar }}>
                            <TableRow>
                              <TableCell />
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Matters</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Role</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Type</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Policy Number</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Policy Limit</Typography>
                              </TableCell>
                              {/* <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>Pleading services </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography sx={{ color: theme?.palette?.primary?.main }}>deposition services</Typography>
                              </TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data?.person?.paginatedCases?.map((record, index) => (
                              <React.Fragment key={index}>
                                <TableRow>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>
                                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenRow(openRow === index ? null : index)}>
                                      {openRow === index ? (
                                        <Box
                                          sx={{
                                            background: theme?.palette?.primary?.sidebar,
                                            width: "18px",
                                            height: "18px",
                                            textAlign: "center",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <RemoveIcon style={{ color: theme?.palette?.secondary?.btnPrimary, height: "15px" }} />
                                        </Box>
                                      ) : (
                                        <Box
                                          sx={{
                                            background: theme?.palette?.primary?.sidebar,
                                            width: "18px",
                                            height: "18px",
                                            textAlign: "center",
                                            borderRadius: "50%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <AddIcon style={{ color: theme?.palette?.secondary?.btnPrimary, height: "15px" }} />
                                        </Box>
                                      )}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>
                                    {Array.isArray(viewRecord?.cases) && viewRecord?.cases.length > 0 ? viewRecord.cases.join(", ") : "N/A"}
                                  </TableCell>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>{record?.role || "N/A"}</TableCell>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>
                                    {record?.insuranceType ? record.insuranceType : record?.physicianType ? record.physicianType : "N/A"}
                                  </TableCell>

                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>{record?.policyNumber || "N/A"}</TableCell>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>{record?.policyLimit || "N/A"}</TableCell>
                                  {/* <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>{record?.pleadingServe?true :false}</TableCell>
                                  <TableCell sx={{ color: theme?.palette?.primary.main, borderBottom: "none" }}>{record?.depositionRequired?true :false}</TableCell> */}
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                      <Box sx={{ margin: 1 }}>
                                        <Table size="small" aria-label="purchases">
                                          <TableBody>
                                            <TableRow>
                                              <TableCell colSpan={6} sx={{ borderBottom: "none" }}>
                                                <Box sx={{ margin: "2px 0 20px 0" }}>
                                                  <Typography sx={inputPersonStyles?.viewHeading}>Note</Typography>
                                                  <Typography sx={inputPersonStyles?.viewSubHeading}>{record?.note || "N/A"}</Typography>
                                                </Box>
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Stack spacing={10} sx={{ display: "flex" }}>
                        <Pagination setCurrentPage={setCurrentPage} currentPage={currentPage} limit={limit} setLimit={setLimit} totalPage={data?.totalPages} />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </PaperComponent>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewPersonMatterDetails;

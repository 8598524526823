import { Box, Button, CssBaseline, Grid, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../Components/Table";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";
import { GeneratePleadingHeading } from "../../constant/GeneratePleading";
import PopupModel from "../../Components/Modal";
import DocumentPleadingModel from "../../Components/Modal/DocumentPleading";
import moment from "moment";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
import useFileActions from "../../hooks/useFileActions";

const DocumentMedicalPleading = () => {
  const userHeadingTable = ["Matter", "Title", "Date", "Version", "Status", "Actions"];
  const userValuesKeysTable = ["case", "title", "createdAt", "version", "status"];
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [filterSearch, setFilterSearch] = useState({});

  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const [limit, setLimit] = useState(10);
  const { get, setBearerToken } = useAxios();
  const { matter } = useMultiFileUpload();
  const fetchData = async () => {
    let endpoint = `/pleadingGenerate?page=${currentPage}&limit=${limit}`;

    if (searchText) {
      endpoint += `&search=${searchText}`;
    }

    // Add global filter if available
    if (matter) {
      endpoint += `&global=${encodeURIComponent(JSON.stringify(matter))}`;
    }

    // Add filterSearch if available
    if (filterSearch && Object.keys(filterSearch).length > 0) {
      endpoint += `&filter=${encodeURIComponent(JSON.stringify(filterSearch))}`;
    }
    setBearerToken(token);
    const response = await get(endpoint);
    return response?.data;
  };
  const { data } = useQuery(["pleadingGenerate", matter, currentPage, searchText, limit, filterSearch], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  let finalData = data;
  if (finalData?.data) {
    const manipulatedData = data?.data?.map((doc) => ({
      id: doc?._id,
      case: doc?.case?.title,
      caseId: doc?.case?._id,
      type: doc?.type,
      title: doc?.title,
      version: Array.isArray(doc?.file1) && doc.file1.length > 0 ? doc.file1.length : 0,
      status: doc?.status,
      file1: Array.isArray(doc?.file1) ? doc?.file1 : [],
      file2: Array.isArray(doc?.file2) ? doc?.file2 : [],
      deposition: doc?.deposition,
      witness: doc?.witness,
      createdAt: moment(doc?.createdAt).format("MMM DD,YYYY"),
    }));

    // manipulatedData.sort((a, b) => a.title.localeCompare(b.title));

    finalData = {
      ...finalData,
      data: manipulatedData,
    };
  }
  const navigate = useNavigate();
  const [openDeposition, setOpenDeposition] = useState(false);
  const handleOpen = () => setOpenDeposition(true);
  const handleClose = () => setOpenDeposition(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const { handleDownloadZip } = useFileActions();
  const handleButtonClick = (message) => {
    alert(message);
  };

  return (
    <>
      <MainLayout>
        <PopupModel
          open={openDeposition}
          styleProp={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: { xs: "90%", sm: "auto" },
            width: { xs: "90%", sm: "500px" }, // Set a fixed width
            maxWidth: "100%", // Ensure it does not exceed screen size
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: "auto",
            backgroundColor: "#262535",
            boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
            border: "1px solid #686DFC",
          }}
          handleClose={handleClose}
          cards={<DocumentPleadingModel closeModal={handleClose} filter={filterSearch} setFilter={setFilterSearch} />}
        />

        <Grid item xs={12}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#2B2C40",
              color: "white",
            }}
          >
            <Box
              sx={{
                minHeight: "100vh",
                maxHeight: { xs: 2000, sm: 2000 },
                position: "relative",
              }}
            >
              <CssBaseline />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={8}
                  sm={8}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                ></Grid>
                <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={() => navigate("/medicals/generateMedical")}
                    variant="contained"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      mt: { xs: 0, sm: 1.5 },
                      mb: { xs: 0, sm: 2 },
                      boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                      "&:hover, &:focus": {
                        boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                        backgroundColor: "white",
                        color: "#6a1b9a",
                      },
                      fontSize: "12px",
                      backgroundColor: "#686DFC",
                      fontWeight: "600",
                      fontFamily: "poppins",
                      textTransform: "uppercase",
                    }}
                  >
                    <AddIcon fontSize="small" sx={{ marginRight: "4px" }} /> Generate new medical
                  </Button>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box role="tabpanel" sx={{ padding: "16px", color: "#F2F3FB", fontFamily: "sans-serif" }}>
                    <TableComponent
                      filter={true}
                      filterSearch={filterSearch}
                      setFilterSearch={setFilterSearch}
                      handleOpen={handleOpen}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      limit={limit}
                      setLimit={setLimit}
                      data={finalData}
                      headingTable={userHeadingTable}
                      valuesKeys={userValuesKeysTable}
                      status={false}
                      statusActiveUrl={""}
                    //   viewPageUrl={"/pleading/viewPleading"}
                    //   editPageUrl={"/pleading/UpdategeneratePleading"}
                    //   isDownload={true}
                    //   onClickDownload={handleDownloadZip}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </MainLayout>
    </>
  );
};

export default DocumentMedicalPleading;

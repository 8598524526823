import { Autocomplete, Box, Checkbox, FormControl, FormHelperText, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { inputPersonStyles } from "../../pages/Persons/style";
import { theme } from "../../constant/Theme";
import LabeledTextField, { CustomSelectDropDown, NoteInput } from "../../constant/ConstantInputField";
import { useLocation, useParams } from "react-router-dom";

// Function to dynamically choose schema based on 'type'

export const validationSchemaStepTwo = Yup.object({
  caseId: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  policyLimit: Yup.string(),
  policyNumber: Yup.string(),
  note: Yup.string(),
});
export const PersonMatterRenderStep = (activeStep, formik) => {
  const [openCase, setOpenCase] = useState(false);
  const { user } = useSelector((state) => state?.reducer);
  const [allClient, setAllClient] = useState([]);
  const [allCase, setAllCase] = useState([]);
  const location = useLocation();
  const { userData } = location.state || {};
  const { personId } = useParams();
  const isEditMode = location.pathname === "/persons/personsMatter/updatepersonMatter";
  const getAllCase = async () => {
    let url;
    if (isEditMode) {
      url = "caseDropdown";
    } else {
      url = `personsMatter/${personId}`;
    }
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setAllCase(response?.data?.data);
      }
    } catch (error) {}
  };
  const getAllClients = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "getClients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        let result = response?.data?.data.map((v) => v.companyName).filter((name) => name && name.trim() !== ""); // Filter out empty names

        if (result.length === 0) {
          result = ["N/A"]; // Set to "N/A" if no valid data is returned
        }

        const currentCompanyName = formik.values.companyName;
        if (currentCompanyName && currentCompanyName.trim() !== "") {
          setAllClient([...result, currentCompanyName]);
        } else {
          setAllClient(result);
        }
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
      setAllClient(["N/A"]); // Set to "N/A" if the API call fails
    }
  };

  useEffect(() => {
    getAllCase();
    getAllClients();
  }, []);

  const MenuProps = {
    // component: CustomMenu,

    PaperProps: {
      style: {
        width: 250,
        maxHeight: 48 * 4.5 + 8,
      },
    },
  };
  const [checked, setChecked] = React.useState(true);
  const [newCheck, setNewChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  switch (activeStep) {
    case 0:
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color: formik.touched.caseId && Boolean(formik.errors?.caseId) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.primary?.main,
                  marginBottom: "4px", // Spacing between label and input
                  fontFamily: theme?.typography?.fontFamily,
                  ...theme?.typography?.inputHeading,
                }}
              >
                Matters *
              </Typography>
              <FormControl required variant="outlined" size="small" fullWidth error={formik.touched.caseId && Boolean(formik.errors.caseId)} sx={inputPersonStyles?.matterName(formik)}>
                <Autocomplete
                  id="caseId"
                  options={allCase}
                  disableClearable={true}
                  getOptionLabel={(option) => option.title}
                  autoComplete
                  size="small"
                  includeInputInList
                  disabled={isEditMode} // Disable input in edit mode
                  value={allCase.find((option) => option._id === formik.values.caseId) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue("case", newValue?._id || "");
                    formik.setFieldValue("caseId", newValue?._id || "");
                  }}
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Matter"
                      variant="outlined"
                      fullWidth
                      error={formik.touched.caseId && Boolean(formik.errors.caseId)}
                      helperText={formik.touched.caseId && formik.errors.caseId ? formik.errors.caseId : ""}
                      InputLabelProps={{
                        sx: {
                          color: theme?.palette?.primary?.main,
                          "&.Mui-focused": {
                            color: theme?.palette?.primary?.main,
                          },
                        },
                      }}
                      sx={{
                        backgroundColor: isEditMode ? theme?.palette?.primary?.subSecondary : "#FFFFFF",
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: theme?.palette?.secondary?.inputColor, // Default border color
                          },
                          "&:hover fieldset": {
                            borderColor: theme?.palette?.secondary?.hoverColor, // Border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: theme?.palette?.secondary?.hoverColor, // Border color when focused
                          },
                          "&.Mui-disabled fieldset": {
                            borderColor: "#505058 !important", // Red border when disabled (using !important to ensure it's applied)
                          },
                          "&.Mui-disabled": {
                            backgroundColor: "#FFFFFF", // Background color when disabled
                          },
                        },
                        "& .MuiInputBase-input.Mui-disabled": {
                          color: "#505058  !important", // Ensure text color is red when input is disabled
                          WebkitTextFillColor: "#505058 !important",
                        },
                      }}
                    />
                  )}
                  PaperComponent={(props) => <Paper {...props} sx={{ backgroundColor: "#FFFFFF", color: theme?.palette?.primary?.main }} />}
                  ListboxProps={{
                    sx: inputPersonStyles.listbox,
                  }}
                  noOptionsText="No matters found"
                />

                {allCase.length <= 0 && <FormHelperText sx={{ color: theme?.palette?.secondary?.dangerMain }}>No matter is added.</FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                variant="body1"
                sx={{
                  color: formik.touched.role && Boolean(formik.errors?.role) ? theme?.palette?.secondary?.dangerMain : theme?.palette?.primary?.main,
                  mb: "4px",
                  ...theme?.typography?.inputHeading,
                }} // Optional styles for the label
              >
                Role *
              </Typography>
              <CustomSelectDropDown
                id="role"
                label="Role"
                name="role"
                handleBlur
                value={formik.values.role || ""}
                error={formik.touched.role && Boolean(formik.errors.role)}
                helperText={formik.touched.role ? formik.errors.role : ""}
                options={[
                  "Co-Counsel",
                  "Defense Counsel",
                  "Defendant",
                  "Expert Witness",
                  "Fact Witness",
                  "Healthcare Provider",
                  "Insurance Adjuster",
                  "Judge",
                  "Judicial Assistant",
                  "Medical Provider",
                  "Other",
                  "PI Liability Adjuster",
                  "PI UM Adjuster",
                  "Plaintiff",
                  "Police",
                  "Property Adjuster",
                ]}
                formik={formik}
                theme={theme}
                inputStyles={inputPersonStyles?.role(formik)}
                placeholder={"Select Role"}
              />
            </Grid>

            {formik.values.role && formik.values.role.includes("Adjuster") && (
              <>
                <Grid item xs={12} sm={4}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme?.palette?.primary?.main, mb: "4px" }} // Optional styles for the label
                  >
                    Insurance Type
                  </Typography>
                  <CustomSelectDropDown
                    label="Insurance Type"
                    name="insuranceType"
                    value={formik.values.insuranceType}
                    error={formik.touched.role && Boolean(formik.errors.insuranceType)}
                    helperText={formik.touched.role ? formik.errors.insuranceType : ""}
                    options={["Liability Adjuster", "Property Damage Adjuster", "UM Adjuster"]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Insurance Type"}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyNumber" label="Policy Number" placeholder="Enter Policy number" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <LabeledTextField formik={formik} name="policyLimit" label="Policy Limit" placeholder="Enter Policy Limit" required={false} sx={inputPersonStyles?.inputText} />
                </Grid>
              </>
            )}

            {formik.values.role === "Healthcare Provider" && (
              <>
                {" "}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    sx={{ color: theme?.palette?.primary?.main, mb: "4px" }} // Optional styles for the label
                  >
                    Physician Type
                  </Typography>
                  <CustomSelectDropDown
                    label="physician Type"
                    name="physicianType"
                    value={formik.values.physicianType}
                    error={formik.touched.role && Boolean(formik.errors.physicianType)}
                    helperText={formik.touched.role ? formik.errors.physicianType : ""}
                    options={["Treating Physician", "Non-treating Physician"]}
                    formik={formik}
                    theme={theme}
                    inputStyles={inputPersonStyles?.insuranceType(formik)}
                    placeholder={"Select Physician Type"}
                  />
                </Grid>
                <Grid item sm={6}></Grid>
              </>
            )}
            <Grid item xs={12} sm={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="pleadingServe"
                  checked={formik.values.pleadingServe}
                  onChange={() => {
                    formik.setFieldValue("pleadingServe", !formik.values.pleadingServe);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography sx={{ color: theme?.palette?.primary?.placeholderColor, ...theme?.typography?.inputPlaceHolder }}>Served with Pleadings</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  name="depositionRequired"
                  checked={formik.values.depositionRequired}
                  onChange={() => {
                    formik.setFieldValue("depositionRequired", !formik.values.depositionRequired);
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
                <Typography sx={{ color: theme?.palette?.primary?.placeholderColor, ...theme?.typography?.inputPlaceHolder }}>Deposition Required</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <NoteInput
                formik={formik} // Pass formik to manage form state and validation
                label="Note " // Custom label
                placeholder="Add your notes here" // Custom placeholder
                name="note" // Field name for formik to manage the state
                rows={6} // Number of rows
                theme={theme} // Custom theme for color and styles
                inputStyles={inputPersonStyles?.note} // Custom styles for the input
              />
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
